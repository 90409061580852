<ngx-ui-loader></ngx-ui-loader>
<div class="container-fluid" style="padding: 0 1.8rem 1.8rem">
    <h5>Report</h5>

    <div class="col-md-12">
      <mat-card>
          <mat-card-content>
              <div class="row" style="padding: 10px;">
                  <div class="col-md-3">
                    <mat-form-field class="form-element" style="margin-left:2%">
                      <mat-label *ngIf="!idFilter">Transformer Id </mat-label>  
                      <mat-label *ngIf="idFilter">{{idFilter}} </mat-label>  
                      <mat-select   [(value)]="idFilter" >
                          <mat-option  *ngFor="let transid of transformerIds"   (click)='filters("transformerId",transid)'>
                              {{transid}}
                            </mat-option>
                        </mat-select>
                  </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field class="form-element" style="margin-left:2%">
                      <mat-label>Duration </mat-label>  
                      <!-- <mat-label *ngIf="timeFilter">{{timeFilter}} </mat-label> -->
                      <mat-select    [(value)]="timeFilter">
                          <mat-option  *ngFor="let time of timeOptions" [value]="time"  (click)='filters("date",time)'>
                              {{time}}
                            </mat-option>
                        </mat-select>
                  </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field class="form-element" style="margin-left:2%" >
                      <mat-label>Params</mat-label>    
                      <mat-select   [ngModel]="parametersOption">
                          <mat-option  *ngFor="let param of parametersOption" [value]="parametersOption"  (click)='filters("parameters",param)'>
                              {{param}}
                            </mat-option>
                        </mat-select>
                  </mat-form-field>
                  </div>
                 
              </div>
              <div class="row">
                <p style="margin-left: 2%;"><b>Download In:</b></p>
                <div>
                  <button style="margin-left: 2%;"  mat-button (click)="exporter.exportTable('xlsx',{fileName:'Report'})"><img src="../../assets/images/icons8-microsoft-excel-30.png">
                  </button>
                </div>
                <div>
                  <button   mat-button  (click)="exportToPdf()"><img src="../../assets/images/icons8-pdf-30.png"></button>
                </div>
                <p></p>
                <div>
                  <button  mat-button  (click)="refreshData()"><b>Refresh: </b><img style="margin-left: 2%;" src="../../assets/images/icons8-refresh-30.png"></button>
                </div>
                
              </div>
          </mat-card-content>
      </mat-card>
  </div>

    <!-- <div class="col-md-12">
        
        <mat-form-field class="form-element" style="margin-left:2%">
            <mat-label>Transformer Id</mat-label>    
            <mat-select   [ngModel]="transformerId">
                <mat-option  *ngFor="let id of transformerId"  [value]="transformerId" (click)='filters("transformerId",id)'>
                    {{id}}
                  </mat-option>
              </mat-select>
        </mat-form-field>

        <mat-form-field class="form-element" style="margin-left:2%">
          <mat-label>Time</mat-label>    
          <mat-select   [ngModel]="timeOptions">
              <mat-option  *ngFor="let time of timeOptions" [value]="time"  (click)='filters("date",time)'>
                  {{time}}
                </mat-option>
            </mat-select>
      </mat-form-field>

      <mat-form-field class="form-element" style="margin-left:2%" >
        <mat-label>Params</mat-label>    
        <mat-select   [ngModel]="parametersOption">
            <mat-option  *ngFor="let param of parametersOption" [value]="parametersOption"  (click)='filters("parameters",param)'>
                {{param}}
              </mat-option>
          </mat-select>
    </mat-form-field>
    
      <button style="margin-left: 2%;" class="mat-raised-button mat-primary" mat-button (click)="exporter.exportTable('xlsx',{fileName:'SmartReport'})">Export to Excel<span class="material-icons">
        get_app
        </span></button>
    
    
      <button style="margin-left: 2%;" class="mat-raised-button mat-primary" btn-primary mat-button  (click)="exportToPdf()">Export to PDF <span class="material-icons">
        get_app
        </span></button>
    

   
      <button style="margin-left: 2%;" class="mat-raised-button mat-primary" btn-primary mat-button  (click)="refreshData()"> <span class="material-icons">
        refresh
        </span></button>
    
      
    </div> -->
    <div class="row col-md-12" style="margin-top: 2%;">
        <div class="col-md-12">
          <div >
            <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" matSort class="example-container mat-elevation-z8" >
                <div *ngFor="let col of reportColumns">

              <ng-container   [matColumnDef]="col">
                <div *ngIf="col">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style=" background-color:wheat; color: black;"> {{col}} </th>
                </div>
                  <div *ngIf="col">
                      <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
                  </div>
                  
                </ng-container>
              </div>
                <tr mat-header-row *matHeaderRowDef="reportColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: reportColumns;"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
          </div>    
        </div>
    </div>
    </div>