import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SiteManagerService = /** @class */ (function () {
    function SiteManagerService(http) {
        this.http = http;
    }
    SiteManagerService.prototype.getSiteList = function (data) {
        return this.http.get("getSiteList?roleid=" + data.roleid + "&userid=" + data.userid);
    };
    SiteManagerService.prototype.getTransformerList = function (data) {
        return this.http.get("getTransformerList?roleid=" + data.roleid + "&userid=" + data.userid);
    };
    SiteManagerService.prototype.getUserListSite = function () {
        return this.http.get('getUserListSite');
    };
    SiteManagerService.prototype.addSite = function (body) {
        return this.http.post('sitemanager', body);
    };
    SiteManagerService.prototype.deleteSite = function (data) {
        return this.http.delete("deleteSite?siteid=" + data.id);
    };
    SiteManagerService.prototype.updateSiteData = function (body, siteid) {
        return this.http.put("sitemanager?siteid=" + siteid, body);
    };
    SiteManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SiteManagerService_Factory() { return new SiteManagerService(i0.ɵɵinject(i1.HttpClient)); }, token: SiteManagerService, providedIn: "root" });
    return SiteManagerService;
}());
export { SiteManagerService };
