import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DeviceMasterService = /** @class */ (function () {
    function DeviceMasterService(http) {
        this.http = http;
    }
    DeviceMasterService.prototype.saveDeviceData = function (data) {
        var payload = data;
        console.log('PAYLOAD:', payload);
        return this.http.post('addDevice', payload);
    };
    DeviceMasterService.prototype.getDeviceData = function () {
        return this.http.get('getAllDevice');
    };
    DeviceMasterService.prototype.updateDeviceData = function (data) {
        var payload = data;
        console.log('PAYLOAD:', payload);
        return this.http.post('updateDevice', payload);
    };
    DeviceMasterService.prototype.getDeviceById = function (data) {
        // getUserList?roleid=${data.roleid}&userid=${data.userid}
        // let payload = data;
        return this.http.get("getDeviceById?deletedFlag=" + data.deletedFlag + "&deviceId=" + data.deviceId);
    };
    DeviceMasterService.prototype.deleteData = function (deviceId) {
        var payload = deviceId;
        return this.http.post('deleteDevice', payload);
    };
    DeviceMasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeviceMasterService_Factory() { return new DeviceMasterService(i0.ɵɵinject(i1.HttpClient)); }, token: DeviceMasterService, providedIn: "root" });
    return DeviceMasterService;
}());
export { DeviceMasterService };
