import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TransformerManagerService = /** @class */ (function () {
    function TransformerManagerService(http) {
        this.http = http;
    }
    TransformerManagerService.prototype.saveTransformerData = function (data) {
        var payload = data;
        return this.http.post('addUpdateTransformer', payload);
    };
    TransformerManagerService.prototype.getTransformerData = function (roleid, userid, module) {
        return this.http.get("getTransformerData?roleid=" + roleid + "&userid=" + userid + "&module=" + module);
    };
    TransformerManagerService.prototype.getDeviceDdl = function () {
        return this.http.get('getDeviceDdl');
    };
    TransformerManagerService.prototype.getTransformerDetails = function (data) {
        console.log('DATA:', data);
        return this.http.get("getTransformerDetails?transformerId=" + data.transformerId + "&deletedFlag=" + data.deletedFlag);
    };
    TransformerManagerService.prototype.deleteTransformer = function (data) {
        var payload = data;
        return this.http.post('deleteTransformer', payload);
    };
    TransformerManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransformerManagerService_Factory() { return new TransformerManagerService(i0.ɵɵinject(i1.HttpClient)); }, token: TransformerManagerService, providedIn: "root" });
    return TransformerManagerService;
}());
export { TransformerManagerService };
