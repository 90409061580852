import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ReportService = /** @class */ (function () {
    function ReportService(http) {
        this.http = http;
    }
    ReportService.prototype.getDistinctTransformerId = function () {
        return this.http.get('getDistinctTransformerId');
    };
    ReportService.prototype.getDistinctSite = function () {
        return this.http.get('getDistinctSite');
    };
    ReportService.prototype.getAlertReportData = function (payload) {
        var url = '';
        console.log('PAyload:', payload);
        return this.http.post('getAlertsReport', payload);
    };
    ReportService.prototype.getAlertsData = function (roleid, userid) {
        var url = '';
        return this.http.get("getAlertsData?roleid=" + roleid + "&userid=" + userid);
    };
    ReportService.prototype.getSystemKwh = function (filter) {
        var url = '';
        return this.http.post("getSystemKwh", filter);
    };
    ReportService.prototype.getReportData = function (payload) {
        var url = '';
        // console.log('FILTERS:', filter);
        // if(filter.length > 0) {
        //     url = `getReport1?` + filter;
        // }
        //  else if(filter.transformerId && filter.date) {
        //      url = `getReport1?transformerId=${filter.transformerId}` + '&' + `date=${filter.date}`
        //      console.log('URL:', url);
        //  }
        // else {
        //     url = `getReport1`;
        // }
        console.log('PAyload:', payload);
        return this.http.post('getReport1', payload);
    };
    ReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportService_Factory() { return new ReportService(i0.ɵɵinject(i1.HttpClient)); }, token: ReportService, providedIn: "root" });
    return ReportService;
}());
export { ReportService };
