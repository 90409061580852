import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GlobalService = /** @class */ (function () {
    function GlobalService(http) {
        this.http = http;
    }
    GlobalService.prototype.golbalMaster = function (masterName) {
        return this.http.get("globalMaster?name=" + masterName);
    };
    GlobalService.prototype.countrySelection = function (countryid) {
        return this.http.get("getCountryCode?countryid=" + countryid);
    };
    GlobalService.prototype.getStateList = function (country) {
        return this.http.get("getStateList?countryid=" + country.id);
    };
    GlobalService.prototype.getCityList = function (state) {
        return this.http.get("getCityList?countryid=" + state.country_id + "&stateid=" + state.id);
    };
    GlobalService.prototype.getZoneList = function (city) {
        return this.http.get("getZoneList?cityid=" + city.id);
    };
    GlobalService.prototype.getSiteLocationList = function (zone) {
        return this.http.get("getSiteLocationList?zoneid=" + zone.id);
    };
    GlobalService.prototype.getfilteredTransformers = function (data, userData) {
        return this.http.get("filteredTransformers?filterdata=" + data + "&userData=" + userData);
    };
    GlobalService.prototype.getnotification = function (body) {
        return this.http.post("notification", body);
    };
    GlobalService.prototype.updatenotification = function (body) {
        return this.http.post("updatenotification", body);
    };
    GlobalService.prototype.setLoginLocalStorage = function (response) {
        localStorage.setItem('token', response.access_token);
        localStorage.setItem('userData', JSON.stringify({ roleid: response.roleid, userid: response.userid, name: response.name, countryid: response.country_id, utility: response.utility ? response.utility : null }));
    };
    GlobalService.prototype.getLoginLocalStorage = function () {
        return localStorage.getItem('userData');
    };
    GlobalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(i0.ɵɵinject(i1.HttpClient)); }, token: GlobalService, providedIn: "root" });
    return GlobalService;
}());
export { GlobalService };
