import { NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./sse.service";
import * as i2 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(_zone, _sseService, http) {
        this._zone = _zone;
        this._sseService = _sseService;
        this.http = http;
    }
    DashboardService.prototype.getServerSentEvent = function (url) {
        var _this = this;
        return Observable.create(function (observer) {
            var es = _this._sseService.getEventSource(url);
            es.onmessage = function (event) {
                _this._zone.run(function () { return observer.next(event); });
            };
            es.onerror = function (error) {
                observer.error(error);
            };
        });
    };
    DashboardService.prototype.loadProfile = function (data) {
        return this.http.get("loadProfile?transformerlist=" + data);
    };
    DashboardService.prototype.getWeatherData = function (data) {
        return this.http.get("https://api.openweathermap.org/data/2.5/weather?lat=" + data.latitude + "&lon=" + data.longitude + "5&appid=d519f7e16e00303bc0e8e9b0850ead0a");
    };
    DashboardService.prototype.alertCount = function (body) {
        return this.http.post("alertCount", body);
    };
    DashboardService.prototype.transformerCount = function (body) {
        return this.http.post("transformerCount", body);
    };
    DashboardService.prototype.loadIndividualDashboard = function (body) {
        return this.http.post("loadIndividualDashboard", body);
    };
    DashboardService.prototype.transformerTileData = function (body) {
        return this.http.post("transformerTileData", body);
    };
    DashboardService.prototype.transformerDowntime = function (body) {
        return this.http.post("getTransformerDowntime", body);
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.SseService), i0.ɵɵinject(i2.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
