<ngx-ui-loader [loaderId]="'dashboard'"></ngx-ui-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">

                    <mat-card>
                        <mat-card-content>

                            <div class="row" style="padding: 10px;">
                                <div class="col-sm-4 col-md-2">
                                    <ng-select [items]="countryList" bindLabel="name" placeholder="Country"
                                        [(ngModel)]="selectedCountry" (change)="countrySelection()" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="col-sm-4 col-md-2">
                                    <ng-select [items]="stateList" bindLabel="name" placeholder="State"
                                        [(ngModel)]="selectedState" (change)="stateSelection()">
                                    </ng-select>
                                </div>
                                <div class="col-sm-4 col-md-2">
                                    <ng-select [items]="cityList" bindLabel="name" placeholder="City"
                                        [(ngModel)]="selectedCity" (change)="citySelection()">
                                    </ng-select>
                                </div>
                                <div class="col-sm-4 col-md-2">
                                    <ng-select [items]="zoneList" bindLabel="name" placeholder="Zone"
                                        [(ngModel)]="selectedZone" (change)="zoneSelection()">
                                    </ng-select>
                                </div>
                                <div class="col-sm-4 col-md-2">
                                    <ng-select [items]="locationList" bindLabel="name" placeholder="Location"
                                        [(ngModel)]="selectedLocation" (change)="locationSelection()">
                                    </ng-select>
                                </div>
                                <div class="col-sm-4 col-md-2">
                                    <ng-select [items]="transformerList" bindLabel="transformerid"
                                        placeholder="Transformer" [(ngModel)]="selectedTransformer"
                                        (change)="transformerSelection()">
                                    </ng-select>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-3">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <ngx-ui-loader [loaderId]="'transformerTile'"></ngx-ui-loader>
                            <span *ngIf="transformerTile">
                                <div class="row">
                                    <div class="col-md-3">
                                        <button class="btn btn-primary btn-icon icon-design">
                                            <i class="fa fa-sitemap" style="color: white;"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-3">
                                        <h6>{{transformerTile?.totalTransformer}} </h6>
                                        <p>Transformers</p>
                                        <div style="display: flex;text-align: center;">
                                            <span class="text-success" style="padding-right: 7px;">Online
                                                {{transformerTile?.onlineTransformer}}</span>
                                            <span style="padding-right: 7px;color: #007BFF;">Normal
                                                {{transformerTile?.normalTransformer}}</span>
                                            <span style="padding-right: 7px;color: black;">Offline
                                                {{transformerTile?.offlineTransformer}}</span>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <span *ngIf="!transformerTile">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-3">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <ngx-ui-loader [loaderId]="'kvaTile'"></ngx-ui-loader>
                            <span *ngIf="kvaData">
                                <div class="row">
                                    <div class="col-md-3">
                                        <button class="btn btn-warning btn-icon icon-design">
                                            <i class="fa fa-bolt" style="color: white;"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-3">
                                        <h6>{{kvaData?.total | number:'1.2-3'}} </h6>
                                        <p>KVA</p>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-1">
                                        <i class="fa fa-info-circle tooltipp">  
                                            <span class="tooltiptext">The KVA reading being reported is the last KVA reading recorded on the last hear beat. If both Transformer are operational the KVA reported is accumulative of both transformers.If only one transformer is operational the KVA reported is the KVA reported for one transformer only. If no transformers are online then last recorded KVA are reported.</span>
                                        </i>     
                                    </div>
                                </div>
                            </span>
                            <span *ngIf="!kvaData">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-3">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <ngx-ui-loader [loaderId]="'kwTile'"></ngx-ui-loader>
                            <span *ngIf="kwData">
                                <div class="row">
                                    <div class="col-md-3">
                                        <button class="btn btn-success btn-icon icon-design">
                                            <i class="fa fa-bolt" style="color: white;"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-3">
                                        <h6>{{kwData?.total | number:'1.2-3'}} </h6>
                                        <p>KW</p>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-1">
                                        <i class="fa fa-info-circle tooltipp">
                                            <span class="tooltiptext">The KW reading being reported is the last KW reading recorded on the last hear beat. If both Transformer are operational the KW reported is accumulative of both transformers.If only one transformer is operational the KW reported is the KW reported for one transformer only. If no transformers are online then last recorded KW are reported.</span>
                                        </i>     
                                    </div>
                                </div>
                            </span>
                            <span *ngIf="!kwData">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </span>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-3">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <ngx-ui-loader [loaderId]="'kwhTile'"></ngx-ui-loader>
                            <span *ngIf="kwhData">
                                <div class="row">
                                    <div class="col-md-3">
                                        <button class="btn btn-danger btn-icon icon-design">
                                            <i class="fa fa-bolt" style="color: white;"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-1">
                                    </div>
                                    <div class="col-md-3">
                                        <h6>{{kwhData?.total | number:'1.2-3'}} </h6>
                                        <p>KWH</p>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-1">
                                        <i class="fa fa-info-circle tooltipp">
                                            <span class="tooltiptext">The KWH reading being reported is the last KWH reading recorded on the last hear beat. If both Transformer are operational the KWH reported is accumulative of both transformers.If only one transformer is operational the KWH reported is the KWH reported for one transformer only. If no transformers are online then last recorded KWH are reported.</span>
                                        </i>     
                                    </div>
                                </div>
                            </span>
                            <span *ngIf="!kwhData">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </span>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-12">
                    <mat-card>
                        <mat-card-content>
                            <agm-map #gm [latitude]=lat [longitude]=lng [zoom]="4" [fullscreenControl]='true'
                                [mapTypeControl]='true'>
                                <agm-marker *ngFor="let data of mapData; let i = index" [latitude]="data.latitude"
                                    [longitude]="data.longitude" (mouseOver)="onMouseOver(infoWindow,gm)"
                                    (markerClick)="transformerClicked(data)"
                                    [iconUrl]="data.datareceived && data.alert.length == 0 ? greenTransformerPath : data.datareceived && data.alert.length > 0 ? redTransformerPath : blackTransformerPath">
                                    <agm-info-window [disableAutoPan]="false" #infoWindow>
                                        <p>Transformer Id: {{data.transformerid}}</p>
                                        <p>Site Name: {{data.sitename}}</p>
                                        <p>KVA: {{data.Kva ? (data?.Kva | number:'1.2-3') : '-'}}</p>
                                        <p>KWh: {{data.Kwh ? (data?.Kwh | number:'1.2-3') : '-'}}</p>
                                        <p>Load(%): {{data?.Load ? (data?.Load | number:'1.2-3') : '-'}}</p>
                                        <p *ngIf="data.datareceived" style="color: green;">Power: On</p>
                                        <span *ngIf="!data.datareceived" style="color: red;">
                                            <p>Power: Off</p>
                                        </span>
                                        <span *ngIf="data?.alert.length > 0" style="color: red;">
                                            <p *ngFor="let param of data.alert">
                                                {{param.parametername | slice:2}}:{{param.mparametervalue}}
                                            </p>
                                        </span>
                                    </agm-info-window>
                                </agm-marker>
                            </agm-map>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-6">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <p><b>LOAD PROFILE</b></p>
                            <hr>
                            <ngx-ui-loader [loaderId]="'dataLoadProfile'"></ngx-ui-loader>
                            <div *ngIf="dataLoadProfile && dataLoadProfile.length > 0">
                                <plotly-plot [data]="dataLoadProfile" [layout]="graphLoadProfile.layout"
                                    [config]="graphLoadProfile.config" [useResizeHandler]="true">
                                </plotly-plot>
                            </div>
                            <div *ngIf="dataLoadProfile && dataLoadProfile.length == 0">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-md-6">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <p><b>Alerts</b></p>
                            <hr>
                            <ngx-ui-loader [loaderId]="'dataAlertCount'"></ngx-ui-loader>
                            <div class="row" style="margin-bottom: 7px;">
                                <div class="col-md-4">
                                    <ng-select [items]="durationList" bindLabel="name" placeholder="Duration"
                                        [(ngModel)]="selectedDurationAlert"
                                        (change)="durationSelectionAlert(selectedDurationAlert,'alert')" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div *ngIf="showDateRange1">
                                    <input [owlDateTime]="dt2" [selectMode]="'range'" [owlDateTimeTrigger]="dt2"
                                        placeholder="Select Date Range" (dateTimeChange)="changeDateRange1($event)">
                                    <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                </div>
                            </div>
                            <div *ngIf="dataAlertCount && dataAlertCount.length > 0">
                                <plotly-plot [data]="dataAlertCount" [layout]="graphAlertCount.layout"
                                    [config]="graphAlertCount.config" [useResizeHandler]="true">
                                </plotly-plot>
                            </div>
                            <div *ngIf="dataAlertCount && dataAlertCount.length == 0">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-6">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <p><b>Weather</b></p>
                            <hr>
                            <div class="row" style="margin-bottom: 7px;">
                                <div class="col-md-4">
                                    <ng-select [items]="transformerList" bindLabel="transformerid"
                                        placeholder="Transformer" [(ngModel)]="selectedTransformerForWeather"
                                        (change)="transformerSelectionWeather(selectedTransformerForWeather)"
                                        [clearable]="false">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row" *ngIf="weatherData">
                                <div class="col-md-6">
                                    <span style="font-size: 14px;">
                                        <p>{{weatherData?.location}} , {{weatherData?.city}} ,
                                            {{weatherData?.state}}
                                        </p>
                                        <p>{{weatherData?.weatherType}}</p>
                                    </span><br>
                                    <p style="font-size: 20px;"><b>{{weatherData?.temperature }}°C</b></p>
                                </div>
                                <div class="col-md-6" style="font-size: 14px;margin-top: 22px;padding-left: 40px;">
                                    <p>Humidity : {{weatherData?.humidity}} %</p>
                                    <p>Wind : {{weatherData?.wind | number:'1.0-0'}} km/h</p>
                                </div>
                            </div>
                            <span *ngIf="!weatherData">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </span>
                        </mat-card-content>
                    </mat-card>
                    <br>
                </div>
                <div class="col-md-6">
                    <mat-card>
                        <mat-card-content style="padding: 12px!important;">
                            <p><b>Transformer Alert</b></p>
                            <hr>
                            <ngx-ui-loader [loaderId]="'dataTransformerAlert'"></ngx-ui-loader>
                           
                            <div class="row" style="margin-bottom: 7px;">
                                <div class="col-md-4">
                                    <ng-select [items]="parameterList" bindLabel="name" placeholder="Select Parameter"
                                        [(ngModel)]="selectedParameter" (change)="parameterChange(selectedParameter)" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="col-md-4">
                                    <ng-select [items]="durationList" bindLabel="name" placeholder="Duration"
                                        [(ngModel)]="selectedDuration"
                                        (change)="durationSelectionAlert(selectedDuration,'talert')" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div *ngIf="showDateRange" class="col-md-4" style="padding-top: 20px">
                                    <input [owlDateTime]="dt1" [selectMode]="'range'" [owlDateTimeTrigger]="dt1"
                                        placeholder="Select Date Range" (dateTimeChange)="changeDateRange($event)">
                                    <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                </div>
                            </div>
                            
                            <div *ngIf="dataTransformerAlert && dataTransformerAlert.length > 0">
                                <plotly-plot [data]="dataTransformerAlert" [layout]="graphTransformerAlert.layout"
                                    [config]="graphTransformerAlert.config" [useResizeHandler]="true">
                                </plotly-plot>
                            </div>
                            <div *ngIf="dataTransformerAlert && dataTransformerAlert.length == 0">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </div>

                        </mat-card-content>
                    </mat-card>
                </div>
                <button class="mdc-fab app-fab--absolute" color="warn" (click)='openAlertBox()' aria-label="alert">
                    <div class="mdc-fab__ripple"></div>
                    <p style="color: whitesmoke;">Alerts</p>
                    <span class="material-icons" style="color: whitesmoke;">
                        notifications_active
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>