import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { IndividualDashboardComponent } from './individual-dashboard/individual-dashboard.component';
import { UserComponent } from './user/user.component';
import { AuthGuard } from './auth.guard';
import { SiteManagerComponent } from './site-manager/site-manager.component';
import { DemandTrendAnalysisComponent } from './demand-trend-analysis/demand-trend-analysis.component';
import { DemandTrendAnalysisAvailabilityComponent } from './demand-trend-analysis-availability/demand-trend-analysis-availability.component';
import { TransfomerManagerComponent } from './transformer/transformer-manager/transfomer-manager.component';
import { DeviceManagerComponent } from './device/device-master/devicemaster.component';
import { ReportComponent } from './reports/report.component';
import { AlertReportComponent } from './reports/alert/alert-report.component';
import { AccessAuthGuard } from './access-auth.guard';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { DowntimeComponent } from './demand-trend-analysis/downtime/downtime/downtime.component';
import { OnoffreportComponent } from './reports/onlineoffline/onoffreport/onoffreport.component';
import { SystemkwhComponent } from './reports/systemkwh/systemkwh.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/signin',
    pathMatch: 'full'
  },
  {
    path: 'auth/signin',
    component: LoginComponent
  },
  {
    path: '', component: SideNavComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AccessAuthGuard]},
      { path: 'individual-status', component: IndividualDashboardComponent, canActivate: [AccessAuthGuard] },
      { path: 'user', component: UserComponent, canActivate: [AccessAuthGuard]},
      { path: 'site', component: SiteManagerComponent, canActivate: [AccessAuthGuard]},
      { path: 'demand-trend', component: DemandTrendAnalysisComponent, canActivate: [AccessAuthGuard] },
      { path: 'downtime-analysis', component: DemandTrendAnalysisAvailabilityComponent, canActivate: [AccessAuthGuard] },
      { path: 'Shutdown-analysis', component: DowntimeComponent, canActivate: [AccessAuthGuard] },   
      {path: 'transformerManager', component: TransfomerManagerComponent, canActivate: [AccessAuthGuard]},   
      {path: 'deviceMaster', component: DeviceManagerComponent, canActivate: [AccessAuthGuard]},
      {path: 'report', component: ReportComponent, canActivate: [AccessAuthGuard]},
      {path: 'report/:transformerid', component: ReportComponent, canActivate: [AccessAuthGuard]},
      {path: 'alertReport', component:AlertReportComponent, canActivate: [AccessAuthGuard]},
      {path: 'onoffReport', component:OnoffreportComponent, canActivate: [AccessAuthGuard]},
      {path: 'systemkwhReport', component:SystemkwhComponent, canActivate: [AccessAuthGuard]},
      {path: 'access-denied', component:AccessDeniedComponent, canActivate: [AccessAuthGuard]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
