/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./access-denied.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./access-denied.component";
var styles_AccessDeniedComponent = [i0.styles];
var RenderType_AccessDeniedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccessDeniedComponent, data: {} });
export { RenderType_AccessDeniedComponent as RenderType_AccessDeniedComponent };
export function View_AccessDeniedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Access Denied"]))], null, null); }
export function View_AccessDeniedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-access-denied", [], null, null, null, View_AccessDeniedComponent_0, RenderType_AccessDeniedComponent)), i1.ɵdid(1, 114688, null, 0, i2.AccessDeniedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccessDeniedComponentNgFactory = i1.ɵccf("app-access-denied", i2.AccessDeniedComponent, View_AccessDeniedComponent_Host_0, {}, {}, []);
export { AccessDeniedComponentNgFactory as AccessDeniedComponentNgFactory };
