<h2 mat-dialog-title>Add Device</h2>
<h2 mat-dialog-title *ngIf="data && data.type === 'add'">Add</h2>
<h2 mat-dialog-title *ngIf="data && data.type === 'edit'">Edit</h2>
    <ngx-ui-loader [loaderId]="'add-edit-user'"></ngx-ui-loader>
    <div class="form-container scrollbar scrollbar-primary"  >
<form [formGroup]="deviceForm" class="form">
    <div class="col-md-12">
    <mat-dialog-content>
        <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Device Id</mat-label>
               
                <input type="text" matInput placeholder="Device ID Name" formControlName="deviceId" required [readonly]="readOnly">
            </mat-form-field>
            <span class="error" *ngIf="deviceForm.controls.deviceId.touched && deviceForm.controls.deviceId.errors?.required">
                Please Enter Device ID
            </span>
        </div>
        <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Device Model</mat-label>
                <input type="text" matInput placeholder="Device Model" formControlName="deviceModel">
            </mat-form-field>
            <span class="error" *ngIf="deviceForm.controls.deviceModel.touched && deviceForm.controls.deviceModel.errors?.required">
                Please Enter Device Model
            </span>
        </div>
        <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Device Category</mat-label>
                <input type="text" matInput placeholder="Device Category" formControlName="deviceCategory" required>
            </mat-form-field>
            <span class="form-text error" *ngIf="deviceForm.controls.deviceCategory.touched && deviceForm.controls.deviceCategory.errors?.required">
                Please Enter Device Category
            </span>
           
        </div>
        <!-- <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Version</mat-label>
                <input type="text" matInput placeholder="Version" formControlName="version" required>
            </mat-form-field>
            <span class="form-text error"
                *ngIf="deviceForm.controls.version.touched && deviceForm.controls.version.errors?.required">
                Please Enter Version
            </span>
        </div> -->
        <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Firmware Version</mat-label>
                <input type="text" matInput placeholder="Firmware Version" formControlName="firmwareversion" required>
            </mat-form-field>
            <span class="form-text error"
                *ngIf="deviceForm.controls.firmwareversion.touched && deviceForm.controls.firmwareversion.errors?.required">
                Please Enter Firmware Version
            </span>
        </div>
        <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Hardware Version</mat-label>
                <input type="text" matInput placeholder="Hardware Version" formControlName="hardwareversion" required>
            </mat-form-field>
            <span class="form-text error"
                *ngIf="deviceForm.controls.hardwareversion.touched && deviceForm.controls.hardwareversion.errors?.required">
                Please Enter Hardware Version
            </span>
        </div>
        <div class="row col-md-12">
            <mat-form-field style="width: 100%;">
                <mat-label>Passcode</mat-label>
                <input matInput type="text" placeholder="Passcode" formControlName="passcode"
                    required>
               
            </mat-form-field>
            <span class="form-text error"
                *ngIf="deviceForm.controls.passcode.touched && deviceForm.controls.passcode.errors?.required">
                Please Enter Passcode
            </span>
        </div>
       
    </mat-dialog-content>

    <mat-dialog-actions>
        <button class="mat-raised-button" type="button" (click)="close('NA')">Close</button>
        <button class="mat-raised-button mat-primary" [disabled]="!deviceForm.valid" (click)="onSubmit(deviceForm.value)">Save</button>
    </mat-dialog-actions>
</div>
</form>
</div>