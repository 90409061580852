import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from "lodash";
var IndividualDashboardComponent = /** @class */ (function () {
    function IndividualDashboardComponent(globalService, _dashboardService, ngxLoader) {
        this.globalService = globalService;
        this._dashboardService = _dashboardService;
        this.ngxLoader = ngxLoader;
        this.parameterValuedisplay = {};
        this.graph = {
            layout: {
                autosize: true,
                xaxis: {
                    title: {
                        text: 'Time',
                        font: {
                            family: 'Arial',
                            size: 18,
                            color: '#000000',
                        }
                    },
                },
                yaxis: {
                    title: {
                        text: 'Value',
                        font: {
                            family: 'Arial',
                            size: 18,
                            color: '#000000'
                        }
                    }
                },
                shapes: []
            },
            config: { displaylogo: false, modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian'] }
        };
        this.selectedTransformer = null;
        this.transformerList = [];
        this.selectedParameter = null;
        this.parameterList = [];
        this.parameterListTemp = [];
        this.masterparameterList = [];
        this.durationList = [
            { id: 1, name: 'Today' },
            { id: 2, name: 'Yesterday' },
            { id: 3, name: 'This Week' },
            { id: 4, name: 'This Month' },
            { id: 5, name: 'Last Month' },
            { id: 6, name: 'Custom' }
        ];
        this.selectedDuration = this.durationList[0];
        this.data = [];
        this.showtableData = false;
    }
    IndividualDashboardComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payloadtogetTransformer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ngxLoader.startLoader('individualdashboard');
                        payloadtogetTransformer = {
                            "countryid": null,
                            "stateid": null,
                            "cityid": null,
                            "zoneid": null,
                            "locationid": null,
                            "transformerid": null,
                        };
                        return [4 /*yield*/, this.getParameterList()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getfilteredTransformers(JSON.stringify(payloadtogetTransformer), JSON.stringify(localStorage.getItem('userData')))];
                    case 2:
                        _a.sent();
                        this.ngxLoader.stopLoader('individualdashboard');
                        return [2 /*return*/];
                }
            });
        });
    };
    IndividualDashboardComponent.prototype.getParameterList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.globalService.golbalMaster('filterparameters').subscribe(function (response) {
                if (response && response.code === '1' && response.result.length > 0) {
                    console.log('response====Filter Parameters========', response);
                    //this.selectedParameter = response.result[0];
                    //this.parameterList = response.result;
                    _this.parameterListTemp = response.result;
                    resolve(_this.parameterListTemp);
                }
            });
        });
    };
    IndividualDashboardComponent.prototype.getfilteredTransformers = function (payloadtogetTransformer, userData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.globalService.getfilteredTransformers(payloadtogetTransformer, localStorage.getItem('userData')).subscribe(function (response) {
                _this.ngxLoader.stopLoader('individualdashboard');
                if (response && response.code === '1' && response.result.length > 0) {
                    console.log('response=====Individual Dashboard=========', response);
                    _this.selectedTransformer = response.result[0];
                    _this.transformerList = response.result.map(function (a) {
                        a.transformerDetail = a.transformerid + "[" + a.sitename + "]";
                        return a;
                    });
                    //this.transformerList = response.result;
                    //this.setParameterListForTransformer(this.selectedTransformer);
                    var currentDate = moment(moment().format('YYYY-MM-DD'));
                    var manufactureYear = moment(moment(_this.selectedTransformer.manufacturingdate).format('YYYY-MM-DD'));
                    _this.ageTransformer = currentDate.diff(manufactureYear, 'years');
                    var duration = _this.durationSelectionAlert({ id: 1, name: 'Today' });
                    _this.getData(_this.selectedTransformer, _this.selectedParameter, duration);
                    resolve(_this.transformerList);
                }
            });
        });
    };
    /* setParameterListForTransformer(selectedTransformer: any) {
      if (selectedTransformer.harmonic_current === 0) {
        this.parameterList = _.remove(this.parameterList, function (n) {
          return n.name !== "Harmonics Current";
        });
      }
      if (selectedTransformer.harmonic_voltage === 0) {
        this.parameterList = _.remove(this.parameterList, function (n) {
          return n.name !== "Harmonics Voltage";
        });
      }
      if (selectedTransformer.systemKwh === "0") {
        this.parameterList = _.remove(this.parameterList, function (n) {
          return n.name !== "System_kwh";
        });
      }
    } */
    IndividualDashboardComponent.prototype.durationSelectionAlert = function (selectedDuration) {
        console.log('selectedDuration====', selectedDuration);
        var date = {
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
        };
        if (selectedDuration.id == 2) {
            date.startdate = moment().subtract(1, 'day').format('YYYY-MM-DD');
            date.enddate = moment().subtract(1, 'day').format('YYYY-MM-DD');
        }
        if (selectedDuration.id == 3) {
            date.startdate = moment().startOf('week').format('YYYY-MM-DD');
            date.enddate = moment().endOf('week').format('YYYY-MM-DD');
        }
        if (selectedDuration.id == 4) {
            date.startdate = moment().startOf('month').format('YYYY-MM-DD');
            date.enddate = moment().endOf('month').format('YYYY-MM-DD');
        }
        if (selectedDuration.id == 5) {
            date.startdate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            date.enddate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        }
        if (selectedDuration.id == 6) {
            this.showDateRange = true;
        }
        else {
            this.showDateRange = false;
        }
        console.log('selectedDuration==durationSelectionAlert==', date);
        return date;
    };
    IndividualDashboardComponent.prototype.transformerSelection = function (selectedTransformer) {
        /* this.parameterList=_.cloneDeep(this.parameterListTemp);
        this.setParameterListForTransformer(selectedTransformer); */
        var currentDate = moment(moment().format('YYYY-MM-DD'));
        var manufactureYear = moment(moment(selectedTransformer.manufacturingdate).format('YYYY-MM-DD'));
        this.ageTransformer = currentDate.diff(manufactureYear, 'years');
        var selectedDuration = this.durationSelectionAlert(this.selectedDuration);
        this.getData(selectedTransformer, this.selectedParameter, selectedDuration);
    };
    IndividualDashboardComponent.prototype.parameterSelection = function (selectedParameter) {
        var selectedDuration = this.durationSelectionAlert(this.selectedDuration);
        this.getData(this.selectedTransformer, selectedParameter, selectedDuration);
    };
    IndividualDashboardComponent.prototype.durationSelection = function (selectedDuration) {
        var duration = this.durationSelectionAlert(selectedDuration);
        this.getData(this.selectedTransformer, this.selectedParameter, duration);
    };
    IndividualDashboardComponent.prototype.changeDateRange = function (event) {
        console.log("event----------", event);
        if (this.showDateRange) {
            var date = {
                startdate: moment(event.value[0]).format('YYYY-MM-DD'),
                enddate: moment(event.value[1]).format('YYYY-MM-DD')
            };
            this.getData(this.selectedTransformer, this.selectedParameter, date);
        }
    };
    IndividualDashboardComponent.prototype.getData = function (selectedTransformer, selectedParameter, selectedDuration) {
        var _this = this;
        this.ngxLoader.startLoader('individualdashboardgraph');
        console.log('payload=====selectedDuration====', selectedDuration);
        //let duration = this.durationSelectionAlert(selectedDuration);
        var payload = { transformerid: '', startdate: '', enddate: '' };
        payload.transformerid = "'" + selectedTransformer.transformerid + "'";
        payload.startdate = selectedDuration.startdate;
        payload.enddate = selectedDuration.enddate;
        console.log('payload=========', payload);
        var filteredParams = [];
        this._dashboardService.loadIndividualDashboard(payload).subscribe(function (response) {
            /*       if (response && response.code === '1') {
                    let params: any = response.physicalParams;
                    // const elecParams: any = response.elecParams;
                    // if ( elecParams &&  elecParams.length > 0) {
                      const elecParameters = ['KVA', 'PF', 'Ampere', 'Voltage', 'Harmonics Current', 'Harmonics Voltage', 'Load Percentage', 'I Unbalance', 'V Unbalance', 'V Variance', 'KVAr', 'KW', 'KWh'];
                      params =  params.concat(elecParameters)
                   // }
                    this.masterparameterList.map(x => {
                      if (params.includes(x.name)) {
                        filteredParams.push(x);
                      }
                     
                    });
                  }
                  this.parameterList = filteredParams; */
            if (response && response.code === '1' && response.result.length > 0) {
                var params = response.physicalParams;
                var elecParameters = ['KVA', 'PF', 'Ampere', 'Voltage', 'Load Percentage', 'I Unbalance', 'V Unbalance', 'V Variance', 'KVAr', 'KW'];
                params = params.concat(elecParameters);
                //console.log("Params++++++++++++",params);
                for (var i = 0; i < _this.parameterListTemp.length; i++) {
                    var element = _this.parameterListTemp[i];
                    if (params.includes(element.name)) {
                        filteredParams.push(element);
                    }
                }
                filteredParams = _.map(filteredParams, function (o) {
                    if (o.name === 'Harmonics Current') {
                        o.name = 'Current THD';
                    }
                    else if (o.name === 'Harmonics Voltage') {
                        o.name = 'Voltage THD';
                    }
                    return o;
                });
                _this.parameterList = filteredParams;
                //console.log("Params+++++parameterList+++++++",this.parameterList);
                if (selectedParameter) {
                }
                else {
                    _this.selectedParameter = _this.parameterList[0];
                    selectedParameter = _this.parameterList[0];
                    //console.log("+++++this.selectedParameter+++++++",this.selectedParameter);
                }
                _this.ngxLoader.stopLoader('individualdashboardgraph');
                var responseData = _.flatMap(response.result, function (o) {
                    var readings = JSON.parse(o.readings);
                    o.modifiedreadings = readings;
                    o.Load = (((readings.L1 + readings.L2 + readings.L3) / 3) / +o.ratedLoad) * 100;
                    var avgCurrent = (readings.L1 + readings.L2 + readings.L3) / 3;
                    var currentDeviation = _.maxBy([(readings.L1 - avgCurrent), (readings.L2 - avgCurrent), (readings.L3 - avgCurrent)]);
                    o.iUnbalance = ((currentDeviation * 100) / avgCurrent).toFixed(2);
                    var avgVoltage = (readings.RY_Voltage + readings.YB_Voltage + readings.BR_Voltage) / 3;
                    var voltageDeviation = _.maxBy([(readings.RY_Voltage - avgVoltage), (readings.YB_Voltage - avgVoltage), (readings.BR_Voltage - avgVoltage)]);
                    o.vUnbalance = ((voltageDeviation * 100) / avgVoltage).toFixed(2);
                    o.Frequency = readings.Frequency;
                    return o;
                });
                console.log('response====loadIndividualDashboard======', response);
                var filter = _this.getSelectedParameterFilter(selectedParameter, response.paramsalertValues);
                if (selectedParameter.showcalulationtable == 1) {
                    _this.showtableData = true;
                }
                else {
                    _this.showtableData = false;
                }
                _this.getChartData(responseData, filter);
            }
            else {
                _this.showtableData = false;
                _this.data = [];
                _this.ngxLoader.stopLoader('individualdashboardgraph');
            }
        }, function (error) {
            _this.ngxLoader.stopLoader('individualdashboardgraph');
        });
    };
    IndividualDashboardComponent.prototype.getSelectedParameterFilter = function (selectedParameter, paramsalertValuesList) {
        var parameterValue = {};
        this.parameterValuedisplay = {};
        var obj = { filter: '', type: 'direct', phases: null, parameterValue: null, ismultiBreakpoint: false, multiBreakpointValue: [] };
        if (selectedParameter.id == 1) {
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apOiltemperature" });
            obj.filter = 'Oil_Temperature';
            /* this.parameterValuedisplay.name1="Oil Temperature";
            this.parameterValuedisplay.value1=parameterValue.parameterValue; */
            obj.parameterValue = parameterValue.parameterValue;
        }
        if (selectedParameter.id == 2) {
            this.parameterValuedisplay.name1 = "Rated KVA ";
            this.parameterValuedisplay.value1 = this.selectedTransformer.kvarating;
            obj.phases = { phaseA: 'R_apparent_Power', phaseB: 'Y_apparent_Power', phaseC: 'B_apparent_Power' };
        }
        if (selectedParameter.id == 3) {
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apLowPowerfactor" });
            obj.phases = { phaseA: 'R_Power_Factor', phaseB: 'Y_Power_Factor', phaseC: 'B_Power_Factor' };
            /* this.parameterValuedisplay.name1="PF";
            this.parameterValuedisplay.value1=parameterValue.parameterValue; */
            obj.parameterValue = parameterValue.parameterValue;
        }
        if (selectedParameter.id == 4) {
            obj.filter = 'Frequency';
        }
        if (selectedParameter.id == 5) {
            obj.ismultiBreakpoint = true;
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apOverload" });
            obj.multiBreakpointValue.push(parameterValue.parameterValue);
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apUnderload" });
            obj.multiBreakpointValue.push(parameterValue.parameterValue);
            /*
            this.parameterValuedisplay.name1="OverLoad";
            this.parameterValuedisplay.value1=parameterValue.parameterValue;
            parameterValue=_.find(paramsalertValuesList,{parameterName: "apUnderload"});
            this.parameterValuedisplay.name2="Underload";
            this.parameterValuedisplay.value2=parameterValue.parameterValue; */
            obj.phases = { phaseA: 'L1', phaseB: 'L2', phaseC: 'L3' };
        }
        if (selectedParameter.id == 6) {
            obj.ismultiBreakpoint = true;
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apHighvolt" });
            obj.multiBreakpointValue.push(parameterValue.parameterValue);
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apLowvolt" });
            obj.multiBreakpointValue.push(parameterValue.parameterValue);
            obj.phases = { phaseA: 'R_Ph_N', phaseB: 'Y_Ph_N', phaseC: 'B_Ph_N' };
        }
        if (selectedParameter.id == 7) {
            obj.phases = { phaseA: 'IR_THD', phaseB: 'IY_THD', phaseC: 'IB_THD' };
        }
        if (selectedParameter.id == 8) {
            obj.phases = { phaseA: 'VR_THD', phaseB: 'VY_THD', phaseC: 'VB_THD' };
        }
        if (selectedParameter.id == 9) {
            obj.filter = 'Ambient_Temperature';
        }
        if (selectedParameter.id == 10) {
            obj.filter = 'Humidity';
        }
        if (selectedParameter.id == 11) {
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apWindingtemperature" });
            obj.filter = 'Winding_Temperature';
            /* this.parameterValuedisplay.name1="Winding Temperature";
            this.parameterValuedisplay.value1=parameterValue.parameterValue; */
            obj.parameterValue = parameterValue.parameterValue;
        }
        if (selectedParameter.id == 12) {
            obj.filter = 'Load';
        }
        if (selectedParameter.id == 13) {
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apIunbalance" });
            obj.filter = 'iUnbalance';
            /* this.parameterValuedisplay.name1="I Unbalance";
            this.parameterValuedisplay.value1=parameterValue.parameterValue; */
            obj.parameterValue = parameterValue.parameterValue;
        }
        if (selectedParameter.id == 14) {
            parameterValue = _.find(paramsalertValuesList, { parameterName: "apVunbalance" });
            obj.filter = 'vUnbalance';
            /* this.parameterValuedisplay.name1="V Unbalance";
            this.parameterValuedisplay.value1=parameterValue.parameterValue; */
            obj.parameterValue = parameterValue.parameterValue;
        }
        if (selectedParameter.id == 15) {
            obj.phases = { phaseA: 'R_Ph_N', phaseB: 'Y_Ph_N', phaseC: 'B_Ph_N' };
        }
        if (selectedParameter.id == 16) {
            obj.phases = { phaseA: 'R_Reactive_Power', phaseB: 'Y_Reactive_Power', phaseC: 'B_Reactive_Power' };
        }
        if (selectedParameter.id == 17) {
            obj.phases = { phaseA: 'R_Active_Power', phaseB: 'Y_Active_Power', phaseC: 'B_Active_Power' };
        }
        if (selectedParameter.id == 18) {
            obj.filter = 'System_kwh';
            obj.type = 'json';
        }
        if (selectedParameter.id == 19) {
            obj.filter = 'Oil_Level';
        }
        return obj;
    };
    IndividualDashboardComponent.prototype.getChartData = function (data, filterObj) {
        this.data = [];
        this.graph.layout.shapes = [];
        if (filterObj.parameterValue) {
            this.graph.layout.shapes = [
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: filterObj.parameterValue,
                    x1: 1,
                    y1: filterObj.parameterValue,
                    line: {
                        color: 'rgb(255, 0, 0)',
                        width: 4
                    }
                }
            ];
        }
        else if (filterObj.ismultiBreakpoint) {
            for (var i = 0; i < filterObj.multiBreakpointValue.length; i++) {
                this.graph.layout.shapes.push({
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: filterObj.multiBreakpointValue[i],
                    x1: 1,
                    y1: filterObj.multiBreakpointValue[i],
                    line: {
                        color: 'rgb(255, 0, 0)',
                        width: 4
                    }
                });
            }
        }
        if (filterObj.filter && filterObj.type === 'direct') {
            var filterKey_1 = filterObj.filter;
            var value = _.map(data, filterKey_1);
            var time = _.map(data, 'DATE');
            /* const date = _.map(data, 'mdate'); */
            console.log("====getOilTempData======", value, time);
            var traces = {
                x: time,
                y: value,
                mode: 'lines+markers',
                type: 'scatter',
            };
            this.data = [traces];
            console.log("====getOilTempData=traces=====", this.data, filterObj);
            if (this.showtableData) {
                this.tableData = {
                    max: _.maxBy(data, filterKey_1)[filterKey_1],
                    min: _.minBy(data, filterKey_1)[filterKey_1],
                    range: _.maxBy(data, filterKey_1)[filterKey_1] - _.minBy(data, filterKey_1)[filterKey_1],
                    avg: _.sumBy(data, function (o) { return Number(o[filterKey_1]); }) / data.length
                };
                console.log("====tableData=showtableData=====", this.tableData);
            }
        }
        else if (filterObj.filter && filterObj.type === 'json') {
            var filterKey_2 = filterObj.filter;
            var value = _.map(data, function (o) { return o.modifiedreadings[filterKey_2]; });
            var time = _.map(data, 'DATE');
            /*  const date = _.map(data, 'mdate'); */
            console.log("====getOilTempData==ELSE====", value, time);
            var traces = {
                x: time,
                y: value,
                mode: 'lines+markers',
                type: 'scatter',
            };
            this.data = [traces];
        }
        if (filterObj.phases) {
            var filterKey_3 = filterObj.phases;
            var phaseAValue = _.map(data, function (o) { return Math.abs(o.modifiedreadings[filterKey_3.phaseA]); });
            var phaseBValue = _.map(data, function (o) { return Math.abs(o.modifiedreadings[filterKey_3.phaseB]); });
            var phaseCValue = _.map(data, function (o) { return Math.abs(o.modifiedreadings[filterKey_3.phaseC]); });
            var time = _.map(data, 'DATE');
            var date = _.map(data, 'mdate');
            var traces1 = {
                x: time,
                y: phaseAValue,
                name: 'R Phase',
                mode: 'lines+markers',
                type: 'scatter',
                marker: {
                    color: 'red'
                }
                /*  text: date */
            };
            var traces2 = {
                x: time,
                y: phaseBValue,
                name: 'Y Phase',
                mode: 'lines+markers',
                type: 'scatter',
                marker: {
                    color: 'yellow'
                }
                /* text: date */
            };
            var traces3 = {
                x: time,
                y: phaseCValue,
                name: 'B Phase',
                mode: 'lines+markers',
                type: 'scatter',
                marker: {
                    color: 'blue'
                }
                /* text: date */
            };
            console.log("====getOilTempData=traces=PHASES====", data, filterObj.phases);
            var tracesList = [traces1, traces2, traces3];
            if (filterObj.phases.phaseA === 'L1') {
                var tempTraces = {
                    x: time,
                    y: _.map(data, function (o) { return o.modifiedreadings['LN']; }),
                    name: 'LN',
                    mode: 'lines+markers',
                    type: 'scatter',
                    marker: {
                        color: 'green'
                    }
                };
                tracesList.push(tempTraces);
            }
            if (filterObj.phases.phaseA === 'R_Power_Factor') {
                var tempTraces = {
                    x: time,
                    y: _.map(data, function (o) { return o.modifiedreadings['Avg_Power_Factor']; }),
                    name: 'Avg Power Factor',
                    mode: 'lines+markers',
                    type: 'scatter',
                    marker: {
                        color: 'green'
                    }
                };
                tracesList.push(tempTraces);
            }
            if (filterObj.phases.phaseA === 'R_Ph_N') {
                var tempTraces = {
                    x: time,
                    y: _.map(data, function (o) { return o['Average_Voltage']; }),
                    name: 'Avg Voltage',
                    mode: 'lines+markers',
                    type: 'scatter',
                    marker: {
                        color: 'green'
                    }
                };
                tracesList.push(tempTraces);
            }
            this.data = tracesList;
        }
    };
    return IndividualDashboardComponent;
}());
export { IndividualDashboardComponent };
