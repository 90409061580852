import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig } from '@angular/material';
import 'jspdf-autotable';
import { CustomDateComponent } from './customdate/custom-date.component';
import * as moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as _ from "lodash";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var ReportComponent = /** @class */ (function () {
    function ReportComponent(reportService, matDialog, ngxLoader, route, transMngSer) {
        this.reportService = reportService;
        this.matDialog = matDialog;
        this.ngxLoader = ngxLoader;
        this.route = route;
        this.transMngSer = transMngSer;
        this.commonColumns = ["Transformer Id", "Deviceid", "DATE"];
        this.report1Columns = ["Transformer Id", "Deviceid", "DATE", "Winding_Temperature", "Winding_Temp_By_Cal", "Ambient_Temperature", "Humidity", "Oil_Temperature", "Oil_Level", "R_Ph_N", "Y_Ph_N", "B_Ph_N", "Average_Voltage", "RY_Voltage", "YB_Voltage", "BR_Voltage", "L1", "L2", "L3", "LN", "I1", "I2", "I3", "R1", "R2", "R3", "R_Power_Factor", "Y_Power_Factor", "B_Power_Factor", "Avg_Power_Factor", "R_Active_Power", "Y_Active_Power", "B_Active_Power", "3_Phase_Active_Power", "R_Reactive_Power", "Y_Reactive_Power", "B_Reactive_Power", "3_Phase_Reactive_Power", "R_apparent_Power", "Y_apparent_Power", "B_apparent_Power", "3_Phase_Apparent_Power", "Fequency", "Device_Temperature", "VR_THD", "VY_THD", "VB_THD", "IR_THD", "IY_THD", "IB_THD", "RP_THD", "YP_THD", "BP_THD", "System_kwh", "System_kvArh"];
        this.filter = {};
        this.transformerIds = [];
        this.timeOptions = ['Today', 'Yesterday', 'This Week', 'This Month', 'Last Month', 'Custom'];
        this.parametersOption = ['All', 'Physical Parameters', "Electrical Parameters"];
        this.reportData = [];
        this.electricalParams = [];
        this.allParameters = [];
        this.windingtemprature = [];
    }
    ReportComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.route.snapshot.queryParams['transformerId']) return [3 /*break*/, 2];
                        this.transformerParam = this.route.snapshot.queryParams['transformerId'];
                        this.ngxLoader.start();
                        _a = this;
                        return [4 /*yield*/, this.getDdlData()];
                    case 1:
                        _a.transformerIds = _c.sent();
                        if (this.transformerIds.length > 0) {
                            this.idFilter = this.transformerParam;
                            this.timeFilter = 'Today';
                            this.filter.transformerId = _.cloneDeep(this.transformerParam).split('[')[0];
                            this.filters('date', 'Today');
                        }
                        else {
                            this.ngxLoader.stop();
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        this.ngxLoader.start();
                        _b = this;
                        return [4 /*yield*/, this.getDdlData()];
                    case 3:
                        _b.transformerIds = _c.sent();
                        if (this.transformerIds.length > 0) {
                            if (this.idFilter) {
                                this.transformerParam = this.idFilter;
                            }
                            else {
                                this.transformerParam = this.transformerIds[0];
                                this.idFilter = this.transformerParam;
                            }
                            this.filter.transformerId = _.cloneDeep(this.transformerParam).split('[')[0];
                            // var todayFilter: any = {};
                            // todayFilter = await this.filters('date', 'Today');
                            // console.log('TODAY FILTER:', todayFilter);
                            // this.filter = {...this.filter, ...todayFilter};
                            this.timeFilter = 'Today';
                            this.filters('date', 'Today');
                        }
                        else {
                            this.ngxLoader.stop();
                        }
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ReportComponent.prototype.getDdlData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.transMngSer.getTransformerData(JSON.parse(localStorage.getItem('userData')).roleid, JSON.parse(localStorage.getItem('userData')).userid, 'report').toPromise().then(function (response) {
                if (response.data.length > 0) {
                    resolve(response.data.map(function (a) { return a.serialnumber + "[" + a.sitename + "]"; }));
                }
                resolve([]);
            });
        });
    };
    ReportComponent.prototype.filters = function (type, param) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filter, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        console.log(type, param);
                        if (!(type === 'transformerId')) return [3 /*break*/, 1];
                        this.idFilter = param;
                        // this.filter = ;
                        //this.filter.transformerId = param 
                        this.transformerParam = param;
                        this.filter.transformerId = _.cloneDeep(this.transformerParam).split('[')[0];
                        this.fetchReportData(this.filter);
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(type === 'date')) return [3 /*break*/, 3];
                        this.timeFilter = param;
                        return [4 /*yield*/, this.formatDate(param)];
                    case 2:
                        filter = _a.sent();
                        //this.filter = this.filter + filter;
                        if (filter.filter === 'This Week' || filter.filter === 'This Month' || filter.filter === 'Last Month') {
                            if (this.filter && this.filter.date) {
                                delete this.filter.date;
                            }
                            this.filter.startDate = filter.startDate;
                            this.filter.endDate = filter.endDate;
                            this.filter.filter = filter.filter;
                        }
                        else if (filter.filter === 'Today' || filter.filter === 'Yesterday') {
                            if (this.filter && this.filter.startDate && this.filter.endDate) {
                                delete this.filter.startDate;
                                delete this.filter.endDate;
                            }
                            this.filter.filter = filter.filter;
                            this.filter.date = filter.date;
                            this.fetchReportData(this.filter);
                        }
                        else if (filter.filter === 'Custom') {
                            if (this.filter && this.filter.date) {
                                delete this.filter.date;
                            }
                            if (filter.startDate && filter.endDate) {
                                this.filter.startDate = filter.startDate;
                                this.filter.endDate = filter.endDate;
                                this.filter.filter = filter.filter;
                            }
                        }
                        if (filter.startDate && filter.endDate) {
                            this.fetchReportData(this.filter);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        if (type === 'parameters') {
                            this.paramFilter = param;
                            if (param === 'Physical Parameters') {
                                this.typeFilter = type;
                                this.reportColumns = this.physicalParams;
                                // this.dataSource = new MatTableDataSource(this.data);;
                                // this.dataSource.paginator = this.paginator;
                                // this.dataSource.sort = this.sort;
                            }
                            else if (param === 'All') {
                                this.reportColumns = this.allParameters;
                            }
                            else if (param === 'Electrical Parameters') {
                                this.reportColumns = this.electricalParams;
                            }
                            else {
                                this.reportColumns = this.allParameters;
                            }
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.log('ERROR:', error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ReportComponent.prototype.formatDate = function (param) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (param === 'Today') {
                var d = new Date(), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                resolve({ 'date': [year, month, day].join('-'), 'filter': param });
                // return [year, month, day].join('-');
            }
            else if (param === 'Yesterday') {
                var d = new Date(), month = '' + (d.getMonth() + 1), day = '' + (d.getDate() - 1), year = d.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                resolve({ 'date': [year, month, day].join('-'), 'filter': param });
                //return [year, month, day].join('-');
            }
            else if (param === 'Last Month') {
                resolve({ 'startDate': moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), 'endDate': moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'), 'filter': param });
                // resolve('&' + `startDate=${[year, month, day].join('-')}` + '&' + `endDate=${[year, month, 30].join('-')}` +  '&' + `filter=${param}`);
                // resolve({'startDate':[year, month, day].join('-'), 'endDate':[year, month, 30].join('-')});
            }
            else if (param === 'This Month') {
                resolve({ 'startDate': moment().startOf('month').format('YYYY-MM-DD'), 'endDate': moment().endOf('month').format('YYYY-MM-DD'), 'filter': param });
                // resolve('&' + `startDate=${[year, month, day].join('-')}` + '&' + `endDate=${[year, month, 30].join('-')}` +  '&' + `filter=${param}`);
            }
            else if (param === 'This Week') {
                resolve({ 'startDate': moment().startOf('week').format('YYYY-MM-DD'), 'endDate': moment().endOf('week').format('YYYY-MM-DD'), 'filter': param });
                //  resolve('&' + `startDate=${[year, month, startDate].join('-')}` + '&' + `endDate=${[year, month, day].join('-')}` +  '&' + `filter=${param}`);
            }
            else if (param === 'Custom') {
                var dialogConfig = new MatDialogConfig();
                var dialogRef = _this.matDialog.open(CustomDateComponent, {
                    autoFocus: false,
                    height: '350px', width: '450px', data: { "details": "", "action": "Add", selectedDate: _this.customeSelectedDate }
                });
                dialogRef.afterClosed().subscribe(function (value) {
                    console.log("Value=======", value);
                    _this.customeSelectedDate = value;
                    resolve({ 'startDate': value.startDate, 'endDate': value.endDate, 'filter': param });
                });
            }
        });
    };
    ReportComponent.prototype.exportToPdf = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var widths, _a, docDefinition, error_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        widths = new Array(this.reportColumns.length).fill('1%');
                        console.log('WIDTHS:', widths, this.reportColumns);
                        _a = this;
                        return [4 /*yield*/, this.formatData(this.data)];
                    case 1:
                        _a.reportData = _b.sent();
                        console.log('Report data length:', this.reportData[0].length);
                        console.log('Formatted Data:', this.reportData[0]);
                        console.log('DATA TO BIND:', this.reportData);
                        docDefinition = {
                            info: {
                                title: 'Transformer Report',
                            },
                            pageOrientation: 'landscape',
                            pageSize: '4A0',
                            content: [
                                {
                                    table: {
                                        // headers are automatically repeated if the table spans over multiple pages
                                        // you can declare how many rows should be treated as headers
                                        headerRows: 1,
                                        // widths: [ '1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%','1.5%', '1.5%' ],
                                        width: widths,
                                        //   body: [
                                        //      "serialnumber", "DATE", "Winding_Temperature", "Ambient_Temperature", "Humidity", "Oil_Temperature", "Oil_Level", "R_Ph_N", "Y_Ph_N", "B_Ph_N", "Average_Voltage", "RY_Voltage", "YB_Voltage", "BR_Voltage", "L1", "L2", "L3", "LN", "I1", "I2", "I3", "R1", "R2", "R3", "R_Power_Factor", "Y_Power_Factor", "B_Power_Factor", "Avg_Power_Factor", "R_Active_Power", "Y_Active_Power", "B_Active_Power", "3_Phase_Active_Power", "R_Reactive_Power", "Y_Reactive_Power", "B_Reactive_Power", "3_Phase_Reactive_Power", "R_apparent_Power", "Y_apparent_Power", "B_apparent_Power", "3_Phase_Apparent_Power", "Fequency", "Energy_Meter_Temprature", "VR_THD", "VY_THD", "VB_THD", "IR_THD", "IY_THD", "IB_THD", "RP_THD", "YP_THD", "BP_THD", "System_kwh", "System_kvArh", "Deviceid", "Parameter1", "Parameter2", "Parameter3", "Parameter4", "Parameter5", "Parameter6", "Parameter7", "Parameter8", "Parameter9", "Parameter10" ],
                                        //     [ "serialnumber", "DATE", "Winding_Temperature", "Ambient_Temperature", "Humidity", "Oil_Temperature", "Oil_Level", "R_Ph_N", "Y_Ph_N", "B_Ph_N", "Average_Voltage", "RY_Voltage", "YB_Voltage", "BR_Voltage", "L1", "L2", "L3", "LN", "I1", "I2", "I3", "R1", "R2", "R3", "R_Power_Factor", "Y_Power_Factor", "B_Power_Factor", "Avg_Power_Factor", "R_Active_Power", "Y_Active_Power", "B_Active_Power", "3_Phase_Active_Power", "R_Reactive_Power", "Y_Reactive_Power", "B_Reactive_Power", "3_Phase_Reactive_Power", "R_apparent_Power", "Y_apparent_Power", "B_apparent_Power", "3_Phase_Apparent_Power", "Fequency", "Energy_Meter_Temprature", "VR_THD", "VY_THD", "VB_THD", "IR_THD", "IY_THD", "IB_THD", "RP_THD", "YP_THD", "BP_THD", "System_kwh", "System_kvArh", "Deviceid", "Parameter1", "Parameter2", "Parameter3", "Parameter4", "Parameter5", "Parameter6", "Parameter7", "Parameter8", "Parameter9", "Parameter10" ],
                                        //    // [ { text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4' ]
                                        //   ]
                                        body: this.reportData,
                                    }
                                }
                            ]
                        };
                        this.ngxLoader.start();
                        pdfMake.createPdf(docDefinition).download();
                        this.ngxLoader.stop();
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _b.sent();
                        console.log('ERROR:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ReportComponent.prototype.formatData = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var dataArr = [];
            var elementCols = Object.keys(data[0]);
            var diffCols = elementCols.filter(function (x) { return !_this.reportColumns.includes(x); });
            dataArr.push(_this.reportColumns);
            data.forEach(function (element) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var rowValues;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            delete element['Parameter 1'];
                            delete element['Parameter 2'];
                            delete element['Parameter 3'];
                            delete element['Parameter 4'];
                            delete element['Parameter 5'];
                            delete element['Parameter 6'];
                            delete element['Parameter 7'];
                            delete element['Parameter 8'];
                            delete element['Parameter 9'];
                            delete element['Parameter 10'];
                            return [4 /*yield*/, this.deleteDynamicColumns(element, diffCols)];
                        case 1:
                            element = _a.sent();
                            rowValues = [];
                            this.reportColumns.forEach(function (col) {
                                rowValues.push(element[col]);
                            });
                            console.log('RE ARRANGED COLS:', rowValues);
                            // delete element['Energy_Meter_Temprature'];
                            //  dataArr.push(Object.values(element));
                            dataArr.push(rowValues);
                            return [2 /*return*/];
                    }
                });
            }); });
            var finalArr = dataArr.filter(function (x) { return !Object.keys(x).includes(diffCols.join(",")); });
            resolve(dataArr);
        });
    };
    ReportComponent.prototype.deleteDynamicColumns = function (element, cols) {
        return new Promise(function (resolve, reject) {
            cols.forEach(function (col) {
                delete element[col];
            });
            resolve(element);
        });
    };
    ReportComponent.prototype.fetchReportData = function (filter) {
        var _this = this;
        this.ngxLoader.start();
        this.windingtemprature = [];
        this.reportService.getReportData(filter).toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var i, i, columnLength, i_1, addition, LoadPercentage, windingTemprature, element, i_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                // this.transformerIds = await this.getDdlData();
                this.allParameters = this.commonColumns.concat(response.physicalParams, response.electricalParam);
                for (i = 0; i < this.allParameters.length; i++) {
                    if (this.allParameters[i] == "Winding_Temperature") {
                        this.allParameters.splice(6, 0, "Winding_Temp_By_Cal");
                    }
                }
                this.physicalParams = this.commonColumns.concat(response.physicalParams);
                this.physicalParams.splice(6, 0, "Winding_Temp_By_Cal");
                this.electricalParams = this.commonColumns.concat(response.electricalParam);
                if (this.paramFilter === 'Physical Parameters') {
                    this.reportColumns = this.commonColumns.concat(response.physicalParams);
                }
                else if (this.paramFilter === 'Electrical Params') {
                    if (response.electricalParam.length > 0) {
                        this.reportColumns = this.commonColumns.concat(response.electricalParam);
                    }
                    else {
                        this.reportColumns = [];
                    }
                }
                else if (this.paramFilter === 'All') {
                    this.reportColumns = this.commonColumns.concat(response.physicalParams, response.electricalParam);
                }
                else {
                    if (response && response.physicalParams && response.electricalParam && response.reportData && response.reportData.length > 0) {
                        this.reportColumns = this.commonColumns.concat(response.physicalParams, response.electricalParam);
                        for (i = 0; i < this.reportColumns.length; i++) {
                            if (this.reportColumns[i] == "Winding_Temperature") {
                                this.reportColumns.splice(6, 0, "Winding_Temp_By_Cal");
                            }
                        }
                    }
                    else {
                        this.reportColumns = [];
                    }
                }
                this.ngxLoader.stop();
                this.transMngSer.getTransformerDetails({ transformerId: this.filter.transformerId, deletedFlag: 'D' }).subscribe(function (response) {
                    _this.gradient = Number(response.details.message.description[0].kwhreading);
                    console.log('TransformerDetails:', response.details.message.description[0].kwhreading);
                });
                if (response && response.reportData && response.reportData.length > 0) {
                    this.dataSource = new MatTableDataSource(response.reportData);
                    columnLength = response.reportData.length;
                    for (i_1 = 0; i_1 < columnLength; i_1++) {
                        addition = Number(response.reportData[i_1]['L1']) + Number(response.reportData[i_1]['L2']) + Number(response.reportData[i_1]['L3']);
                        LoadPercentage = (((addition / 3) / 696) * 100).toFixed(2);
                        windingTemprature = Number(response.reportData[i_1]['Oil_Temperature']) + ((Number(this.gradient) * Number(LoadPercentage)) / 100);
                        this.windingtemprature.push(windingTemprature.toFixed(2));
                    }
                    console.log("Addition", this.gradient);
                    console.log("Winding Temprature", this.windingtemprature);
                    element = [];
                    i_2 = 0;
                    response.reportData.forEach(function (object) {
                        i_2++;
                        object.Winding_Temp_By_Cal = _this.windingtemprature[i_2];
                        object.R_Active_Power = Math.abs(object.R_Active_Power);
                        object.Y_Active_Power = Math.abs(object.Y_Active_Power);
                        object.B_Active_Power = Math.abs(object.B_Active_Power);
                        object['3_Phase_Active_Power'] = Math.abs(object['3_Phase_Active_Power']);
                        object.R_Reactive_Power = Math.abs(object.R_Reactive_Power);
                        object.Y_Reactive_Power = Math.abs(object.Y_Reactive_Power);
                        object.B_Reactive_Power = Math.abs(object.B_Reactive_Power);
                        object['3_Phase_Reactive_Power'] = Math.abs(object['3_Phase_Reactive_Power']);
                        if (object.Buchholz_Status == 0) {
                            object.Buchholz_Status = "Healthy";
                        }
                        if (object.PRV_Status == 0) {
                            object.PRV_Status = "Healthy";
                        }
                        if (object.OSR_Status == 0) {
                            object.OSR_Status = "Healthy";
                        }
                        if (object.OTI_Status == 0) {
                            object.OTI_Status = "Healthy";
                        }
                        if (object.WTI_Status == 0) {
                            object.WTI_Status = "Healthy";
                        }
                        if (object.Buchholz_Status == 1) {
                            object.Buchholz_Status = "Oprated";
                        }
                        if (object.PRV_Status == 1) {
                            object.PRV_Status = "Oprated";
                        }
                        if (object.OSR_Status == 1) {
                            object.OSR_Status = "Oprated";
                        }
                        if (object.OTI_Status == 1) {
                            object.OTI_Status = "Oprated";
                        }
                        if (object.WTI_Status == 1) {
                            object.WTI_Status = "Oprated";
                        }
                    });
                    console.log('REPORT DATA:', response.reportData);
                    this.data = response.reportData;
                }
                else {
                    this.dataSource = [];
                    this.data = [];
                }
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                return [2 /*return*/];
            });
        }); });
    };
    ReportComponent.prototype.generateDataArray = function (data) {
        var reportData = [];
        var rowdata;
        return new Promise(function (resolve, reject) {
            data.forEach(function (element) {
                rowdata = Object.values(element);
            });
            resolve(rowdata);
        });
    };
    ReportComponent.prototype.refreshData = function () {
        this.ngOnInit();
    };
    return ReportComponent;
}());
export { ReportComponent };
