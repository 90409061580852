<ngx-ui-loader [loaderId]="'individualdashboard'"></ngx-ui-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <mat-card>
                        <mat-card-content>
                            <div class="row" style="padding-left: 5px;">
                                <div class="col-md-2" style="margin-top: 8px">
                                    Age of the Transformer :
                                    <p><b>{{ageTransformer}}</b> Year</p>
                                </div>
                                <div class="col-md-3">
                                    <ng-select [items]="transformerList" bindLabel="transformerDetail"
                                        placeholder="Transformer" [(ngModel)]="selectedTransformer"
                                        (change)="transformerSelection(selectedTransformer)" [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <ng-select [items]="parameterList" bindLabel="name" placeholder="Parameters"
                                        [(ngModel)]="selectedParameter" (change)="parameterSelection(selectedParameter)"
                                        [clearable]="false">
                                    </ng-select>
                                </div>
                                <div class="col-md-2">
                                    <ng-select [items]="durationList" bindLabel="name" placeholder="Duration"
                                        [(ngModel)]="selectedDuration" (change)="durationSelection(selectedDuration)"
                                        [clearable]="false">
                                    </ng-select>
                                </div>

                                <div *ngIf="showDateRange" class="col-md-2" style="padding-top: 20px">
                                    <input [owlDateTime]="dt1" [selectMode]="'range'" [owlDateTimeTrigger]="dt1"
                                        placeholder="Select Date Range" (dateTimeChange)="changeDateRange($event)">
                                    <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                </div>

                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-12">
                    <mat-card>
                        <mat-card-content>
                            <p style="padding: 5px;"><b>{{selectedParameter?.name}}</b></p>
                            <hr>
                            <ngx-ui-loader [loaderId]="'individualdashboardgraph'"></ngx-ui-loader>
                            <span style="padding: 5px;" *ngIf="parameterValuedisplay && parameterValuedisplay.name1"><b>{{parameterValuedisplay?.name1}} :- </b></span>
                            <span *ngIf="parameterValuedisplay && parameterValuedisplay.value1"><b>{{parameterValuedisplay?.value1}}</b></span>
                            <span *ngIf="parameterValuedisplay && parameterValuedisplay.name2"><b>{{parameterValuedisplay?.name2}} :- </b></span>
                            <span *ngIf="parameterValuedisplay && parameterValuedisplay.value2"><b>{{parameterValuedisplay?.value2}}</b></span>
                            <div class="row" *ngIf="data.length > 0">
                                <div [ngClass]="showtableData ? 'col-md-10' : 'col-md-12'" style="height: 70vh;">
                                    <plotly-plot [data]="data" [layout]="graph.layout" [config]="graph.config"
                                        [useResizeHandler]="true" [style]="{position: 'relative', width: '100%', height: '100%'}">
                                    </plotly-plot>
                                </div>
                                <span *ngIf="showtableData">
                                    <div class="col-md-2" style="margin-top: 80px;">
                                        <table class="table">
                                            <tbody>
                                                <tr>
                                                    <td>Min</td>
                                                    <td>{{tableData?.min | number:'1.2-3'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Max</td>
                                                    <td>{{tableData?.max | number:'1.2-3'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Average</td>
                                                    <td>{{tableData?.avg | number:'1.2-3'}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Range</td>
                                                    <td>{{tableData?.range | number:'1.2-3'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </div>
                            <div *ngIf="data && data.length == 0">
                                <p class="text-danger">*Note: </p>
                                <p class="alert alert-warning">No data to show results!</p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>