<ngx-ui-loader></ngx-ui-loader>
<div class="container-fluid" style="padding: 0 1.8rem 1.8rem">
    <h5>Transformer Manager</h5>
    <div class="row">
        <div class="col-md-12">
          <div>
            <div class="example-button-row">
              <button mat-raised-button color="primary" (click)="openDialog()">Add</button>
            </div>
            <div fxLayout fxLayoutAlign="center center">
              <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search">
              </mat-form-field>
              
            </div>
            
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z12">
              <ng-container matColumnDef="serialnumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Transformer ID </th>
                <td mat-cell *matCellDef="let element"> {{element.serialnumber}} </td>
              </ng-container>

              <ng-container matColumnDef="sitename">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Site Name </th>
                <td mat-cell *matCellDef="let element"> {{element.sitename}} </td>
              </ng-container>

              <ng-container matColumnDef="deviceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Device ID </th>
                <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
              </ng-container>
            
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button color="warn" (click)="deleteData(element.serialnumber)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
                  <!-- <span class="material-icons" (click)="deleteData(element.serialnumber)">
                    delete
                    </span> -->

                    <button mat-icon-button color="primary" (click)="editData(element.serialnumber)">
                      <mat-icon class="mat-18">edit</mat-icon>
                  </button>
<!-- 
                    <span mat-icon-button color="blue" (click)="editData(element.serialnumber)">
                      edit
                      </span> -->
                      <!-- <span class="material-icons" (click)="fetchDetails(element.serialnumber)">
                        info
                        </span> -->
                </td>
                
              </ng-container>
            
              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <!-- <td mat-cell *matCellDef="let element"> {{element.state}} </td> -->
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.state === 1" style="color: green;">Active</span>
                  <span *ngIf="element.state === 0" style="color: red;">InActive</span>
              </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
          </div>
        </div>

    </div>
    </div>

