<div class="row">
  
  <button mat-icon-button (click)="closeModal()"  color="warn" style="margin-left: 90%;height:3%; display: inline-block;"><mat-icon class="mat-18">clear</mat-icon></button>

  <h5 >ALERTS LOG</h5>
</div>
<div class="col-md-12">
  <mat-card>
      <mat-card-content>
          <div class="row" style="padding: 10px;">
              <div class="col-md-6">
                <mat-form-field class="form-element" >
                  <mat-label>Transformer Id</mat-label>    
                  <mat-select>
                    <mat-option value="All" (click)='filters("transformerId","All")'>All</mat-option>
                      <mat-option  *ngFor="let id of transformerIds"  [value]="id" (click)='filters("transformerId",id)'>
                          {{id}}
                        </mat-option>
                    </mat-select>
              </mat-form-field>
      
              </div>

       
                <div class="col-md-6">
                  <mat-form-field class="form-element" >
                    <mat-label>Parameter</mat-label>    
                    <mat-select  [(ngModel)]="paramSelect">
                      <!-- <mat-option value="All" (click)='filters("param","All")'>All</mat-option> -->
                        <mat-option  *ngFor="let param of parameterType"  [value]="param" (click)='filters("param",param)'>
                            {{param}}
                          </mat-option>
                      </mat-select>
                </mat-form-field>
        
                </div>
              
              </div>

             
              </mat-card-content>
              </mat-card>
</div>
<div >
    <table style="width: 100%;" mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" matSort class="mat-elevation-z8" >
        <ng-container   *ngFor="let col of reportColumns" [matColumnDef]="col">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col}} </th>
          <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="reportColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: reportColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
  </div>  