import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialogRef } from '@angular/material';
var AlertComponent = /** @class */ (function () {
    function AlertComponent(reportSer, dialogRef) {
        this.reportSer = reportSer;
        this.dialogRef = dialogRef;
        this.columns = ["Time Stamp", "Transformer Id", "Status", "Threshold Value", "Actual Value"];
        this.parameterType = ['All', 'Oil Temperature', 'Oil Level', 'Winding Temperature', 'I Unbalance', 'V Unbalance', 'Low Volt', 'High Volt', 'Low Power Factor', 'Overload', 'Underload'];
        this.reportColumns = [];
        this.transformerIds = [];
        this.status = [];
        this.alertLogs = [];
        this.transformerData = [];
        this.filteredData = [];
        this.paramSelect = 'All';
    }
    AlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reportSer.getAlertsData(JSON.parse(localStorage.getItem('userData')).roleid, JSON.parse(localStorage.getItem('userData')).userid).toPromise().then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                console.log('ALERT BOX DATA:', res);
                this.paramFilter = this.paramSelect;
                //  this.parameterType = res.physicalParams;
                this.alertLogs = res.reportData;
                this.transformerIds = res.reportData.map(function (a) { return a["Transformer Id"]; });
                this.transformerIds = tslib_1.__spread(new Set(this.transformerIds));
                this.status = res.reportData.map(function (a) { return a["Status"]; });
                console.log('FILTERS:', this.transformerIds, this.status);
                this.reportColumns = this.columns;
                this.dataSource = new MatTableDataSource(res.reportData);
                //this.data = res.reportData;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                return [2 /*return*/];
            });
        }); });
    };
    AlertComponent.prototype.closeModal = function () {
        this.dialogRef.close();
    };
    AlertComponent.prototype.filters = function (type, param) {
        this.filteredData = this.alertLogs;
        if (type === 'transformerId') {
            if (param !== 'All' && this.paramFilter !== 'All') {
                this.idFilter = param;
                this.filteredData = this.alertLogs.filter(function (x) { return x["Transformer Id"] === param; });
                this.transformerData = this.filteredData;
                this.filteredData = this.filteredData.filter(function (x) { return x['Status'] === param; });
            }
            else if (param !== 'All' && this.paramFilter === 'All') {
                this.filteredData = this.alertLogs.filter(function (x) { return x["Transformer Id"] === param; });
                this.transformerData = this.filteredData;
            }
            else {
                this.filteredData = this.alertLogs;
                this.transformerData = this.filteredData;
            }
        }
        else if (type === 'param') {
            if (param !== 'All') {
                this.paramFilter = param;
                this.paramSelect = param;
                // if(this.idFilter) {
                //   // this.filteredData = this.alertLogs.filter(x => x["Transformer Id"] === this.idFilter);
                //   this.filteredData = this.transformerData;
                //   this.filteredData = this.filteredData.filter(x => x['Status'] === param);
                // } else {
                this.filteredData = this.transformerData.filter(function (x) { return x['Status'] === param; });
                // }
            }
            else if (param === 'All') {
                this.filteredData = this.transformerData;
            }
        }
        this.reportColumns = this.columns;
        this.dataSource = new MatTableDataSource(this.filteredData);
        //this.data = res.reportData;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    };
    return AlertComponent;
}());
export { AlertComponent };
