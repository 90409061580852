<section class="login bg-img">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-3">
                <!-- background-color: #e74141c7; -->
                <mat-card style="border-radius: 8px;margin: 20px 0;border: 2px solid black">
                    <mat-card-title>Sign In</mat-card-title>
                    <mat-card-content>
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Your Email Address</mat-label>
                                    <input type="text" matInput placeholder="Your Email Address" formControlName="email"
                                        required>
                                </mat-form-field>
                                <span class="form-text error"
                                    *ngIf="form.controls.email.touched && form.controls.email.errors?.required">
                                    Please Enter Email Address
                                </span>
                                <span class="form-text error"
                                    *ngIf="form.controls.email.touched && form.controls.email.errors?.email">
                                    Enter Valid Email Address.
                                </span>
                            </p>
                            <p>
                                <mat-form-field appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password"
                                        formControlName="pass" required>
                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field>
                                <span class="form-text error"
                                    *ngIf="form.controls.pass.touched && form.controls.pass.errors?.required">
                                    Please Enter Password
                                </span>
                            </p>
                            <p *ngIf="responseCode === '-1'">
                                <span class="form-text error">
                                    {{showMessage}}
                                </span>
                            </p>

                            <div class="row">
                                <div class="col-md-12">
                                    <button mat-button class="my-btn btn btn-primary">Sign In
                                        <mat-progress-bar class="stretchOut" mode={{buttonMode}} value="0">
                                        </mat-progress-bar>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </div>
</section>