import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransformerManagerService {

    constructor(private http: HttpClient) { }

    saveTransformerData(data: any) {
         let payload = data;
        return this.http.post('addUpdateTransformer', payload)
    }

    getTransformerData(roleid, userid, module) {
       return this.http.get(`getTransformerData?roleid=${roleid}&userid=${userid}&module=${module}`);
    }

    getDeviceDdl() {
      return this.http.get('getDeviceDdl');
    }

    getTransformerDetails(data:any) {
      console.log('DATA:', data);
      return this.http.get(`getTransformerDetails?transformerId=${data.transformerId}&deletedFlag=${data.deletedFlag}`);
    }

    deleteTransformer(data: any) {
      let payload = data;
      return this.http.post('deleteTransformer', payload);
    }
}