import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceMasterService {

    constructor(private http: HttpClient) { }

    saveDeviceData(data: any) {
         let payload = data;
         console.log('PAYLOAD:', payload);
        return this.http.post('addDevice', payload);
    }

    getDeviceData() {
       return this.http.get('getAllDevice');
    }

    updateDeviceData(data:any) {
      let payload = data;
      console.log('PAYLOAD:', payload);
     return this.http.post('updateDevice', payload);
    }

    getDeviceById(data: any) {
      // getUserList?roleid=${data.roleid}&userid=${data.userid}
     // let payload = data;
      return this.http.get(`getDeviceById?deletedFlag=${data.deletedFlag}&deviceId=${data.deviceId}`);
   }

    deleteData(deviceId: any) {
      let payload = deviceId;
      return this.http.post('deleteDevice',payload);
    }
}