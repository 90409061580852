<ngx-ui-loader [loaderId]="'site-manager-list'"></ngx-ui-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-10"></div>
                <div class="col-md-2">
                    <button type="button" class="mat-raised-button mat-primary" (click)=addSite()>Add
                    </button>
                </div>
            </div><br />
            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                        <!-- sitename Column -->
                        <ng-container matColumnDef="sitename">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Site Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.sitename}} </td>
                        </ng-container>

                        <!-- transformerid Column -->
                        <ng-container matColumnDef="transformerid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Transformer Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.transformerid}} </td>
                        </ng-container>

                        <!-- fullname Column -->
                        <ng-container matColumnDef="fullname">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let element"> 
                              <span *ngIf="element.assignedto === 2">{{element.fullname}}</span>   
                              <span *ngIf="element.assignedto === 3">{{element.userfullname}}</span>  
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="blue" (click)="edit(element)">
                                    <mat-icon class="mat-18">create</mat-icon>
                                </button>
                                <button mat-icon-button color="warn" (click)="delete(element)">
                                    <mat-icon class="mat-18">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>