<ngx-ui-loader></ngx-ui-loader>
<div class="container-fluid" style="padding: 0 1.8rem 1.8rem">
    <h5>Alert Report</h5>
    
    <div class="col-md-12">
      <mat-card>
          <mat-card-content>
              <div class="row" style="padding: 10px;">
                  <div class="col-md-3">
                    <mat-form-field class="form-element" >
                      <mat-label>Transformer Id</mat-label>    
                      <mat-select [(value)]="selectedId">
                          <mat-option  *ngFor="let id of transformerId"  [value]="id" (click)='filters("transformerId",id)'>
                              {{id}}
                          </mat-option>
                        </mat-select>
                  </mat-form-field>
          
                  </div>

                  <div class="col-md-3">
                      <mat-form-field class="form-element" style="margin-left:2%" >
                        <mat-label>Site Name</mat-label>    
                        <mat-select   [(value)]="selectedSite" [disabled]="true">
                            <mat-option  *ngFor="let site of sitename" [value]="site"  (click)='filters("sitename",site)'>
                                {{site}}
                              </mat-option>
                          </mat-select>
                    </mat-form-field>
                    </div>

                    
                  <div class="col-md-3">
                    <mat-form-field class="form-element" style="margin-left:2%" >
                      <mat-label>Duration</mat-label>  
                      <!-- <mat-label *ngIf="timeFilter">{{timeFilter}} </mat-label>     -->
                      <mat-select   [(value)]="timeFilter">
                          <mat-option  *ngFor="let time of timeOptions" [value]="time"  (click)='filters("date",time)'>
                              {{time}}
                            </mat-option>
                        </mat-select>
                  </mat-form-field>
                  </div>

                  
                  <div class="col-md-3">
                    <mat-form-field class="form-element" style="margin-left:2%" >
                      <mat-label>Status Value</mat-label>    
                      <mat-select [ngModel]="parameters">
                          <mat-option  *ngFor="let param of parameters" [value]="param" (click)='filterbyParam(param)'>
                              {{param}}
                            </mat-option>
                        </mat-select>
                  </mat-form-field>
                  </div>
                 
              </div>
              <div class="row">
                <p style="margin-left: 2%;"><b>Download In:</b></p>
                <div>
                  <button style="margin-left: 2%;"  mat-button (click)="exporter.exportTable('xlsx',{fileName:'Alert Report'})"><img src="../../assets/images/icons8-microsoft-excel-30.png">
                  </button>
                </div>
                <div>
                  <button   mat-button  (click)="exportToPdf()"><img src="../../assets/images/icons8-pdf-30.png"></button>
                </div>
                <p></p>
                <div>
                  <button  mat-button  (click)="refreshData()"><b>Refresh: </b><img style="margin-left: 2%;" src="../../assets/images/icons8-refresh-30.png"></button>
                </div>
                
              
              </div>
              <div class="row" style="margin-top: 2%;">
                <div class="col-md-3">
                  <p>Transformer Id: {{selectedId}}</p>
                </div>
               <div class="col-md-3">
                <p>City: {{city}}</p>
               </div>
              <div class="col-md-3">
                <p>Location: {{location}}</p>
              </div>
              
              </div>
          </mat-card-content>
      </mat-card>
  </div>
    <div style="margin-top: 2%;">
        <div>
          <div >
            <table style="width: 100%;" mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" matSort class="mat-elevation-z8" >
                <ng-container   *ngFor="let col of reportColumns" [matColumnDef]="col">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="background-color:wheat; color: black;"> <strong>{{col}}</strong> </th>
                  <td mat-cell *matCellDef="let element"> {{element[col]}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="reportColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: reportColumns;"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
          </div>    
        </div>
    </div>
    </div>