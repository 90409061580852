import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig } from '@angular/material';
import 'jspdf-autotable';
import * as moment from 'moment';
import { CustomDateComponent } from '../customdate/custom-date.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var AlertReportComponent = /** @class */ (function () {
    function AlertReportComponent(reportService, matDialog, ngxLoader, transMngSer) {
        this.reportService = reportService;
        this.matDialog = matDialog;
        this.ngxLoader = ngxLoader;
        this.transMngSer = transMngSer;
        this.parameters = ['All', 'Oil Temperature', 'Oil Level', 'Winding Temperature', 'I Unbalance', 'V Unbalance', 'Under Voltage', 'Over Voltage', 'Low Power Factor', 'Overload', 'Underload'];
        this.report1Columns = ["Time Stamp", "Transformer Id", "Status", "Threshold Value", "Actual Value"];
        this.reportData = [];
        this.statusFilterData = [];
        this.filter = {};
        this.transformerId = [];
        this.sitename = [];
        this.reportDataArr = [];
        this.ddlData = [];
        this.timeOptions = ['Today', 'Yesterday', 'This Week', 'This Month', 'Last Month', 'Custom'];
        this.parametersOption = ['All', 'Physical Parameters', 'Electrical Basic Parameters', 'Demand'];
    }
    AlertReportComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.ngxLoader.start();
                        _a = this;
                        return [4 /*yield*/, this.getDdlData()];
                    case 1:
                        _a.transformerId = _b.sent();
                        this.sitename = this.ddlData.map(function (a) { return a.sitename; });
                        this.selectedId = this.transformerId[0];
                        if (this.transformerId.length > 0) {
                            this.timeFilter = 'Today';
                            this.filters('date', 'Today');
                            this.filter.transformerId = this.transformerId[0].split('[')[0];
                        }
                        this.selectedSite = this.ddlData.filter(function (a) { return a.serialnumber === _this.selectedId; });
                        this.city = this.selectedSite[0].City;
                        this.location = this.selectedSite[0].Location;
                        this.selectedSite = this.selectedSite[0].sitename;
                        console.log('selected site:', this.selectedSite);
                        return [2 /*return*/];
                }
            });
        });
    };
    AlertReportComponent.prototype.getDistinctSite = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.reportService.getDistinctSite().toPromise().then(function (response) {
                console.log(response);
                resolve(response.data);
            });
        });
    };
    AlertReportComponent.prototype.getDdlData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.transMngSer.getTransformerData(JSON.parse(localStorage.getItem('userData')).roleid, JSON.parse(localStorage.getItem('userData')).userid, 'report').toPromise().then(function (response) {
                console.log('DDL:', response.data);
                if (response.data.length > 0) {
                    _this.ddlData = response.data;
                    resolve(response.data.map(function (a) { return a.serialnumber + "[" + a.sitename + "]"; }));
                }
                else {
                    resolve([]);
                }
            });
        });
    };
    AlertReportComponent.prototype.filterbyParam = function (param) {
        if (param === 'All') {
            this.statusFilterData = this.data;
        }
        else {
            this.statusFilterData = this.data;
            this.statusFilterData = this.statusFilterData.filter(function (x) { return x.Status === param; });
        }
        this.dataSource = new MatTableDataSource(this.statusFilterData);
        // this.data = response.reportData;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    };
    AlertReportComponent.prototype.filters = function (type, param) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filter, findValues;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('PARAMS:', this.parameter);
                        if (!(type === 'transformerId')) return [3 /*break*/, 1];
                        this.idFilter = param;
                        // this.filter = ;
                        //this.filter.transformerId = param 
                        this.filter.transformerId = param.split('[')[0];
                        this.selectedId = param;
                        this.selectedSite = this.ddlData.filter(function (a) { return a.serialnumber === _this.selectedId.split('[')[0]; });
                        this.city = this.selectedSite[0].City;
                        this.location = this.selectedSite[0].Location;
                        this.selectedSite = this.selectedSite[0].sitename;
                        this.fetchReportData(this.filter);
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(type === 'date')) return [3 /*break*/, 3];
                        this.timeFilter = param;
                        return [4 /*yield*/, this.formatDate(param)];
                    case 2:
                        filter = _a.sent();
                        console.log('DATE FILTERS:', filter);
                        //this.filter = this.filter + filter;
                        if (filter.filter === 'This Week' || filter.filter === 'This Month' || filter.filter === 'Last Month') {
                            if (this.filter && this.filter.date) {
                                delete this.filter.date;
                            }
                            this.filter.startDate = filter.startDate;
                            this.filter.endDate = filter.endDate;
                            this.filter.filter = filter.filter;
                        }
                        else if (filter.filter === 'Today' || filter.filter === 'Yesterday') {
                            if (this.filter && this.filter.startDate && this.filter.endDate) {
                                delete this.filter.startDate;
                                delete this.filter.endDate;
                            }
                            this.filter.date = filter.date;
                            this.filter.filter = filter.filter;
                            this.fetchReportData(this.filter);
                        }
                        else if (filter.filter === 'Custom') {
                            if (this.filter && this.filter.date) {
                                delete this.filter.date;
                            }
                            if (filter.startDate && filter.endDate) {
                                this.filter.startDate = filter.startDate;
                                this.filter.endDate = filter.endDate;
                                this.filter.filter = filter.filter;
                            }
                        }
                        if (filter.startDate && filter.endDate) {
                            this.fetchReportData(this.filter);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        if (type === 'sitename') {
                            this.siteFilter = param;
                            this.filter.sitename = param;
                            findValues = this.ddlData.filter(function (a) { return a.sitename === param; });
                            this.city = findValues[0].City;
                            this.location = findValues[0].Location;
                            this.fetchReportData(this.filter);
                        }
                        _a.label = 4;
                    case 4:
                        console.log('FIILTERS:', this.filter);
                        return [2 /*return*/];
                }
            });
        });
    };
    AlertReportComponent.prototype.formatDate = function (param) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (param === 'Today') {
                var d = new Date(), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                resolve({ 'date': [year, month, day].join('-'), 'filter': param });
                // return [year, month, day].join('-');
            }
            else if (param === 'Yesterday') {
                var d = new Date(), month = '' + (d.getMonth() + 1), day = '' + (d.getDate() - 1), year = d.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                resolve({ 'date': [year, month, day].join('-'), 'filter': param });
                //return [year, month, day].join('-');
            }
            else if (param === 'Last Month') {
                resolve({ 'startDate': moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), 'endDate': moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'), 'filter': param });
            }
            else if (param === 'This Month') {
                resolve({ 'startDate': moment().startOf('month').format('YYYY-MM-DD'), 'endDate': moment().endOf('month').format('YYYY-MM-DD'), 'filter': param });
            }
            else if (param === 'This Week') {
                resolve({ 'startDate': moment().startOf('week').format('YYYY-MM-DD'), 'endDate': moment().endOf('week').format('YYYY-MM-DD'), 'filter': param });
            }
            else if (param === 'Custom') {
                console.log('Custom filter:', param);
                var dialogConfig = new MatDialogConfig();
                var dialogRef = _this.matDialog.open(CustomDateComponent, { autoFocus: false,
                    height: '350px', width: '450px', data: { "details": "", "action": "Add", selectedDate: _this.customeSelectedDate } });
                dialogRef.afterClosed().subscribe(function (value) {
                    _this.customeSelectedDate = value;
                    resolve({ 'startDate': value.startDate, 'endDate': value.endDate, 'filter': param });
                });
            }
        });
    };
    AlertReportComponent.prototype.exportToPdf = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, docDefinition;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.formatData(this.data)];
                    case 1:
                        _a.reportData = _b.sent();
                        console.log('Formatted Data:', this.reportData);
                        docDefinition = {
                            info: {
                                title: 'Transformer Report',
                            },
                            pageOrientation: 'portait',
                            pageSize: 'A0',
                            content: [
                                {
                                    table: {
                                        // headers are automatically repeated if the table spans over multiple pages
                                        // you can declare how many rows should be treated as headers
                                        headerRows: 1,
                                        widths: ['10%', '10%', '10%', '10%', '10%'],
                                        //   body: [
                                        //      "serialnumber", "DATE", "Winding_Temperature", "Ambient_Temperature", "Humidity", "Oil_Temperature", "Oil_Level", "R_Ph_N", "Y_Ph_N", "B_Ph_N", "Average_Voltage", "RY_Voltage", "YB_Voltage", "BR_Voltage", "L1", "L2", "L3", "LN", "I1", "I2", "I3", "R1", "R2", "R3", "R_Power_Factor", "Y_Power_Factor", "B_Power_Factor", "Avg_Power_Factor", "R_Active_Power", "Y_Active_Power", "B_Active_Power", "3_Phase_Active_Power", "R_Reactive_Power", "Y_Reactive_Power", "B_Reactive_Power", "3_Phase_Reactive_Power", "R_apparent_Power", "Y_apparent_Power", "B_apparent_Power", "3_Phase_Apparent_Power", "Fequency", "Energy_Meter_Temprature", "VR_THD", "VY_THD", "VB_THD", "IR_THD", "IY_THD", "IB_THD", "RP_THD", "YP_THD", "BP_THD", "System_kwh", "System_kvArh", "Deviceid", "Parameter1", "Parameter2", "Parameter3", "Parameter4", "Parameter5", "Parameter6", "Parameter7", "Parameter8", "Parameter9", "Parameter10" ],
                                        //     [ "serialnumber", "DATE", "Winding_Temperature", "Ambient_Temperature", "Humidity", "Oil_Temperature", "Oil_Level", "R_Ph_N", "Y_Ph_N", "B_Ph_N", "Average_Voltage", "RY_Voltage", "YB_Voltage", "BR_Voltage", "L1", "L2", "L3", "LN", "I1", "I2", "I3", "R1", "R2", "R3", "R_Power_Factor", "Y_Power_Factor", "B_Power_Factor", "Avg_Power_Factor", "R_Active_Power", "Y_Active_Power", "B_Active_Power", "3_Phase_Active_Power", "R_Reactive_Power", "Y_Reactive_Power", "B_Reactive_Power", "3_Phase_Reactive_Power", "R_apparent_Power", "Y_apparent_Power", "B_apparent_Power", "3_Phase_Apparent_Power", "Fequency", "Energy_Meter_Temprature", "VR_THD", "VY_THD", "VB_THD", "IR_THD", "IY_THD", "IB_THD", "RP_THD", "YP_THD", "BP_THD", "System_kwh", "System_kvArh", "Deviceid", "Parameter1", "Parameter2", "Parameter3", "Parameter4", "Parameter5", "Parameter6", "Parameter7", "Parameter8", "Parameter9", "Parameter10" ],
                                        //    // [ { text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4' ]
                                        //   ]
                                        body: this.reportData,
                                    }
                                }
                            ]
                        };
                        // const documentDefinition = { content: 'This is for testing.' };
                        pdfMake.createPdf(docDefinition).download();
                        return [2 /*return*/];
                }
            });
        });
    };
    AlertReportComponent.prototype.formatData = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var dataArr = [];
            dataArr.push(_this.reportColumns);
            data.forEach(function (element) {
                delete element.City;
                delete element.Location;
                dataArr.push(Object.values(element));
            });
            resolve(dataArr);
        });
    };
    AlertReportComponent.prototype.fetchReportData = function (filter) {
        var _this = this;
        this.ngxLoader.start();
        // this.city = '';
        // this.location = ''; 
        this.reportService.getAlertReportData(filter).toPromise().then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getDdlData()];
                    case 1:
                        _a.transformerId = _b.sent();
                        console.log('Response:', response.reportData, this.transformerId.value);
                        this.reportColumns = this.report1Columns;
                        // if(response && response.reportData && response.reportData.length > 0) {
                        //     this.city = response.reportData[0].City;
                        //     this.location = response.reportData[0].Location;
                        // }
                        this.ngxLoader.stop();
                        this.dataSource = new MatTableDataSource(response.reportData);
                        this.data = response.reportData;
                        //this.parameters = response.physicalParams,
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AlertReportComponent.prototype.generateDataArray = function (data) {
        var reportData = [];
        var rowdata;
        return new Promise(function (resolve, reject) {
            data.forEach(function (element) {
                rowdata = Object.values(element);
                console.log('ROW DATA:', rowdata);
            });
            resolve(rowdata);
        });
    };
    AlertReportComponent.prototype.refreshData = function () {
        this.ngOnInit();
    };
    return AlertReportComponent;
}());
export { AlertReportComponent };
