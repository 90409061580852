import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  constructor() { }

  getEventSource(url):EventSource{
    return new EventSource(url);
  }
}
