import * as i0 from "@angular/core";
var SseService = /** @class */ (function () {
    function SseService() {
    }
    SseService.prototype.getEventSource = function (url) {
        return new EventSource(url);
    };
    SseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SseService_Factory() { return new SseService(); }, token: SseService, providedIn: "root" });
    return SseService;
}());
export { SseService };
