<ngx-ui-loader></ngx-ui-loader>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-3" style="padding: 10px;">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search">
                    </mat-form-field>
                </div>
                <div class="col-md-2" style="margin-top: 16px">
                    <button type="button" class="mat-raised-button mat-primary" (click)=select.open()>Add
                        <mat-select #select (selectionChange)="onSelectRoleValueChange($event.value)"
                            style="display:inline-flex">
                            <mat-option *ngFor="let role of roleList" [value]="role">{{role.name}}</mat-option>
                        </mat-select>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                        <!-- id Column -->
                        <ng-container matColumnDef="first_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                        </ng-container>

                        <!-- Email Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>

                        <!-- Role Column -->
                        <ng-container matColumnDef="role_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                            <td mat-cell *matCellDef="let element"> {{element.role_name}} </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.status === 1" style="color: green;">Active</span>
                                <span *ngIf="element.status === 0" style="color: red;">InActive</span>
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.role_id !== 1 && element.id !=currentUserData?.userid">
                                    <button mat-icon-button color="blue" (click)="edit(element)">
                                        <mat-icon class="mat-18">create</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="delete(element)">
                                        <mat-icon class="mat-18">delete</mat-icon>
                                    </button>
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>