import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
    }
    UserService.prototype.getUserList = function (data) {
        return this.http.get("getUserList?roleid=" + data.roleid + "&userid=" + data.userid);
    };
    UserService.prototype.addUser = function (body) {
        return this.http.post('user', body);
    };
    UserService.prototype.updateUser = function (body, userid) {
        return this.http.put("user?userid=" + userid, body);
    };
    UserService.prototype.deleteUser = function (data) {
        return this.http.delete("user?data=" + data);
    };
    UserService.prototype.getUserAdmin = function (data) {
        return this.http.get("getuserAdmin?utility_id=" + data.utility_id + "&role_id=" + data.role_id);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
