import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig } from '@angular/material';
import { AddDeviceComponent } from '../add-device/add-device.component';
import { ConfirmationDailogComponent } from 'src/app/confirmation-dailog/confirmation-dailog.component';
var DeviceManagerComponent = /** @class */ (function () {
    function DeviceManagerComponent(matDialog, deviceManagerSer, toastr, ngxLoader) {
        var _this = this;
        this.matDialog = matDialog;
        this.deviceManagerSer = deviceManagerSer;
        this.toastr = toastr;
        this.ngxLoader = ngxLoader;
        this.displayedColumns = ['deviceId', 'deviceModel', 'deviceCategory', 'firmwareversion', 'hardwareversion', 'passcode', 'actions'];
        this.doFilter = function (value) {
            _this.dataSource.filter = value.trim().toLocaleLowerCase();
        };
    }
    DeviceManagerComponent.prototype.ngOnInit = function () {
        this.getDeviceData();
    };
    DeviceManagerComponent.prototype.getDeviceData = function () {
        var _this = this;
        this.ngxLoader.start();
        this.deviceManagerSer.getDeviceData().subscribe(function (response) {
            console.log(response);
            if (response && response.code === '1') {
                _this.ngxLoader.stop();
                _this.dataSource = new MatTableDataSource(response.deviceList);
                _this.dataSource.paginator = _this.paginator;
                _this.dataSource.sort = _this.sort;
            }
            else {
                _this.ngxLoader.stop();
                _this.toastr.error('Error Occurred');
            }
        }, function (error) {
            _this.ngxLoader.stop();
            _this.toastr.error('Error Occurred');
        });
    };
    DeviceManagerComponent.prototype.getInfo = function (deviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDeviceById(deviceId)];
                    case 1:
                        data = _a.sent();
                        data[0].action === 'Info';
                        this.openDialog(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    DeviceManagerComponent.prototype.editDevice = function (deviceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDeviceById(deviceId)];
                    case 1:
                        data = _a.sent();
                        this.openDialog(data);
                        return [2 /*return*/];
                }
            });
        });
    };
    DeviceManagerComponent.prototype.getDeviceById = function (deviceId) {
        ;
        var data = this.deviceManagerSer.getDeviceById({ "deletedFlag": "D", "deviceId": deviceId }).toPromise().then(function (response) {
            console.log("Details Response:", response);
            return response.deviceDetails;
        });
        return data;
    };
    DeviceManagerComponent.prototype.deleteData = function (deviceId) {
        var _this = this;
        var dialogRef = this.matDialog.open(ConfirmationDailogComponent);
        dialogRef.afterClosed().subscribe(function (response) {
            if (response) {
                _this.deviceManagerSer.deleteData({ "deletedFlag": "D", "deviceId": deviceId }).subscribe(function (response) {
                    console.log('Response of Delete data:', response);
                    _this.getDeviceData();
                });
            }
        });
    };
    DeviceManagerComponent.prototype.openDialog = function (details) {
        var _this = this;
        var action;
        var dialogRef;
        var dialogConfig = new MatDialogConfig();
        console.log('EDIT DETAILS:', details);
        dialogConfig.data = "some data";
        if (details === "Add") {
            dialogRef = this.matDialog.open(AddDeviceComponent, { autoFocus: false,
                height: '600px', width: '650px', data: { "details": "", "action": "Add" } });
        }
        else if (details.action && details.action === 'Info') {
            dialogRef = this.matDialog.open(AddDeviceComponent, { autoFocus: false,
                maxHeight: '100vh', data: { "details": details, "action": "Info" } });
        }
        else {
            dialogRef = this.matDialog.open(AddDeviceComponent, { autoFocus: false,
                height: '600px', width: '650px', data: { "details": details, "action": "Edit" } });
        }
        dialogRef.afterClosed().subscribe(function (response) {
            console.log('Close MODAL', response);
            if (response && response === "Add") {
                _this.toastr.success('Device Added Successfully');
            }
            else if (response && response === 'Edit') {
                _this.toastr.success('Device Updated Successfully');
            }
            _this.ngOnInit();
        });
    };
    return DeviceManagerComponent;
}());
export { DeviceManagerComponent };
