/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-dailog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./confirmation-dailog.component";
var styles_ConfirmationDailogComponent = [i0.styles];
var RenderType_ConfirmationDailogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationDailogComponent, data: {} });
export { RenderType_ConfirmationDailogComponent as RenderType_ConfirmationDailogComponent };
export function View_ConfirmationDailogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""], ["tabindex", "1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(8, 0, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["mat-dialog-close", ""], ["mat-raised-button", ""], ["tabindex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(12, 0, ["", ""]))], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 7, 0, currVal_3); var currVal_9 = ""; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 7).disabled || null); var currVal_2 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = _co.confirmButtonText; _ck(_v, 8, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 10).disabled || null); var currVal_6 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 11).ariaLabel || null); var currVal_8 = i1.ɵnov(_v, 11).type; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = _co.cancelButtonText; _ck(_v, 12, 0, currVal_10); }); }
export function View_ConfirmationDailogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-dailog", [], null, null, null, View_ConfirmationDailogComponent_0, RenderType_ConfirmationDailogComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmationDailogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDailogComponentNgFactory = i1.ɵccf("app-confirmation-dailog", i7.ConfirmationDailogComponent, View_ConfirmationDailogComponent_Host_0, {}, {}, []);
export { ConfirmationDailogComponentNgFactory as ConfirmationDailogComponentNgFactory };
